import { Controller } from 'stimulus';
import PhotoSwipeLightbox from "photoswipe/dist/photoswipe-lightbox.esm";
import PhotoSwipe from 'photoswipe/dist/photoswipe.esm';
import { EventEmitter } from '../utils/events';

// import "photoswipe/dist/photoswipe.css";

export default class extends Controller {
   connect() {
      const options = {
         gallery: this.element,
         children: 'a',
         showHideAnimationType: 'zoom',
         // loop: false,
         // initialZoomLevel: 0.85,
         pswpModule: () => PhotoSwipe
      };
      const lightbox = new PhotoSwipeLightbox(options);
      lightbox.init();

   }
}
