import { Controller } from 'stimulus';
import PhotoSwipeLightbox from "photoswipe/src/js/lightbox/lightbox";
import PhotoSwipe from 'photoswipe/src/js/photoswipe';
// import 'photoswipe/src/photoswipe.css';
import Swiper from 'swiper';

export default class extends Controller {
   connect() {
      const options = {
         gallery: this.element,
         children: 'a',
         showHideAnimationType: 'zoom',
         loop: false,
         initialZoomLevel: 0.85,
         pswpModule: () => PhotoSwipe
      };
      const lightbox = new PhotoSwipeLightbox(options);
      lightbox.init();

      this.areaThumbs = this.element.querySelector(".area-thumbs");
      this.areaPhotos = this.element.querySelector(".area-photos");

      var galleryThumbs = new Swiper(this.areaThumbs, {
         spaceBetween: 10,
         slidesPerView: 4,
         freeMode: true,
         watchSlidesVisibility: true,
         watchSlidesProgress: true,
      });
      var galleryTop = new Swiper(this.areaPhotos, {
         spaceBetween: 10,
         autoHeight: true,
         navigation: {
            nextEl: this.element.querySelector('.swiper-button-next'),
            prevEl: this.element.querySelector('.swiper-button-prev'),
         },
         thumbs: {
            swiper: galleryThumbs
         },
         pagination: {
            el: this.element.querySelector('.swiper-pagination'),
            type: 'bullets',
            clickable: true
         },
      });
   }
}
