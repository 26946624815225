import axios from 'axios';
import { Controller } from 'stimulus';
import { serializeForm, disabledInputs, enableInputs, toast, applyErrors } from '../utils';
import Swal from 'sweetalert2';
import { EventEmitter } from '../utils/events';

export default class extends Controller {
    alerts = {
        "erro": "<div class='alert alert-danger alert-dismissible fade show' role='alert' data-controller='alert'>"
                    +"<span class='alert-icon'><i class='fas fa-exclamation-circle'></i></span>"
                    +"<span class='alert-text'>{message}</span>"
                    +"<button type='button' class='close' data-dismiss='alert' aria-label='Close' data-action='alert#close'>"
                        +"<span aria-hidden='true'>×</span>"
                    +"</button>"
                +"</div>",
        "sucesso":  "<div class='alert alert-success alert-dismissible fade show' role='alert' data-controller='alert'>"
                        +"<span class='alert-icon'><i class='fas fa-check-circle'></i></span>"
                        +"<span class='alert-text'>{message}</span>"
                        +"<button type='button' class='close' data-dismiss='alert' aria-label='Close' data-action='alert#close'>"
                            +"<span aria-hidden='true'>×</span>"
                        +"</button>"
                    +"</div>",
    };

    loading =   "<a class='text-light mt-2 mb-2 loading-box'>"
                    +"<img src='{root}/img/_layout/loading.svg' width='30'>"
                    +"<small>Aguarde, enviando dados ...</small>"
                +"</a>";

    tagNames = ['INPUT', 'SELECT'];

    connect() {
        this.name = this.element.getAttribute('name');
        this.form = this.element;
        this.method = this.element.getAttribute('method');
        this.tipo = this.element.getAttribute('data-type');
        this.dados = null;
        this.resposta = this.element.querySelector('.status-form-container');
        this.progress = this.element.querySelector('.loading-form-container');
        this.caminho = this.element.getAttribute('action');
        this.button = document.querySelector('.btn-send-form[data-form-name='+this.name+']');
        if (this.button == undefined || this.button == '' || this.button == null) {
            console.warn('DEV: Precisa-se adicionar um data-form-name válido no botão em questão.');
            return;
        }
        this.clickSendButton = this.send.bind(this);
        this.button.addEventListener('click', this.clickSendButton);
        //
        this.onKeyDownHandler = this.onKeyDown.bind(this);
        this.element.addEventListener('keydown', this.onKeyDownHandler);
        //
        this.form.addEventListener('submit', e => { e.preventDefault(); });
    }

    onKeyDown(e) {
        if (this.tagNames.indexOf(document.activeElement.tagName) !== -1 && this.element.contains(document.activeElement) && e.keyCode && (e.keyCode == 13)) {
            e.preventDefault();
            // send
            this.send();
        }
    }

    recaptcha(formData) {
        return new Promise(function(resolve) {
            grecaptcha.ready(function() {
                grecaptcha.execute(SITE_KEY_RECAPTCHA, {action: 'submit'}).then(function(token) {
                    formData.append('_rcp_token', token);
                    resolve();
                });
            });
        });
    }

    async initFormData(serializableAreas){
        this.dados = await serializeForm(serializableAreas);
        if (serializableAreas.length == 0) {
            console.warn('DEV: Precisa-se adicionar um ".form-area-to-send" no formulário ' + name + ' para puxar os campos.');
            return false;
        }
        this.loading = this.loading.replace("{root}", ROOT);

        if (this.tipo == 'redirect' && this.dados){
            this.dados.append('redirect', true);
            this.dados.append('redirect_to', this.form.getAttribute('data-link'));
        }

        if (this.form.getAttribute('data-rcp')) {
            await this.recaptcha(this.dados);
        }

        return true;
    }
    async send(){
        let serializableAreas = this.form.querySelectorAll('.form-area-to-send');
        let initStatus = await this.initFormData(serializableAreas);
        if (!initStatus) return;

        disabledInputs(serializableAreas);
        this.button.disabled = true;
        this.button.classList.add('loading');
        // send form...
        this.sendRequest();
    }
    async sendRequest(){
        let serializableAreas = this.form.querySelectorAll('.form-area-to-send');

        try {
            const response = await axios({
                method: this.method,
                url: this.caminho,
                headers: { 'Content-Type': 'multipart/form-data' },
                data: this.dados
            });
            const data = JSON.parse(response.data);
            
            if (data.status == "success") {
                this.form.reset();
                if (this.resposta) this.resposta.innerHTML = this.alerts.sucesso.replace('{message}', data.message);
                toast({
                    type: 'success',
                    title: data.message
                });
                if (data.immediateRedirect) {
                    window.location = data.redirectTo;
                } else {
                    EventEmitter.dispatch("form_" + this.name + "_send");
                    Swal.fire({
                        type: 'success',
                        title: data.title,
                        icon: 'success',
                        showConfirmButton: true,
                        confirmButtonText: 'Ok',
                        preConfirm: function () {
                            if (data.redirectTo) {
                                window.location = data.redirectTo;
                            }
                            if (data.reload) {
                                window.location.reload();
                            }
                        }
                    });
                }
            } else if (data.status == 'errorFields') {
                EventEmitter.dispatch("form_" + this.name + "_errors");
                applyErrors(serializableAreas, data.campos);
                if (this.resposta) this.resposta.innerHTML = this.alerts.erro.replace('{message}', data.message);
                var message = data.message;

                if (data.campos._erro_) {
                    message = data.campos._erro_.msg;
                }

                toast({
                    type: 'error',
                    title: message
                });
            } else {
                EventEmitter.dispatch("form_" + this.name + "_errors");
                if (this.resposta) this.resposta.innerHTML = this.alerts.erro.replace('{message}', data.message);
                toast({
                    type: 'error',
                    title: data.message
                });
            }

        } catch (error) {
            EventEmitter.dispatch("form_" + this.name + "_errors");
            if (this.resposta) this.resposta.innerHTML = this.alerts.erro.replace('{message}', error);
            toast({
                type: 'error',
                title: error
            });
        }

        this.button.disabled = false;
        this.button.classList.remove('loading');

        enableInputs(serializableAreas);
    }
}