import { Controller } from 'stimulus';

export default class extends Controller {
   connect() {
      this.input = this.element.querySelector('input');
      this.dropdown = this.element.querySelector('.dropdown-search');
      this.cidadesBox = this.dropdown.querySelector('.cidades-box');
      this.bairrosBox = this.dropdown.querySelector('.bairros-box');
      this.condominiosBox = this.dropdown.querySelector('.condominios-box');
      this.items = this.dropdown.querySelectorAll('a');

      this.onKeyUpHandler = this.onKeyUp.bind(this);
      this.input.addEventListener('keyup', this.onKeyUpHandler);

      this.onItemClickHandler = this.onItemClick.bind(this);
      this.items.forEach(item => item.addEventListener('click', this.onItemClickHandler));

      this.onClickOutsideHandler = this.onClickOutside.bind(this);
      this.onKeyUpGeralHandler = this.onKeyUpGeral.bind(this);
      document.addEventListener('click', this.onClickOutsideHandler);
      document.addEventListener('keyup', this.onKeyUpGeralHandler);
   }

   onClickOutside(e) {      
      if (this.element.contains(e.target)) {
         return;
      }
      this.close();
   }
   onKeyUpGeral(e) {
      if (e.keyCode == 27) {
         this.close();
      }
   }

   onItemClick(e) {
      let el = e.target;
      if (el.parentElement.classList.contains('condominios-box')) {
         let indexParentesOpen = el.innerHTML.indexOf('(');
         let indexParentesClose = el.innerHTML.indexOf(')');
         let finalValue = el.innerHTML.replace(el.innerHTML.substring(indexParentesOpen, indexParentesClose + 1), '').trim();
         this.input.value = finalValue;
      } else {
         this.input.value = el.innerHTML;
      }
      this.input.dispatchEvent(new Event('change'));
      this.close();
   }

   onKeyUp(e) {
      let search = this.input.value;
      this.applySearch(search);
   }

   applySearch(term) {
      let cidadesQtd = 0;
      let bairrosQtd = 0;
      let condominiosQtd = 0;

      if (term != "") {

         this.items.forEach(item => {
            if (item.innerHTML.toLowerCase().trim().indexOf(term.toLowerCase().trim()) >= 0) {
               item.classList.remove('hidde');
               if (this.cidadesBox.contains(item)) cidadesQtd++;
               if (this.bairrosBox.contains(item)) bairrosQtd++;
               if (this.condominiosBox.contains(item)) condominiosQtd++;
               return;
            }
            if (!item.classList.contains('hidde')) {
               item.classList.add('hidde');
            }
         });

         if (cidadesQtd > 0) {
            this.cidadesBox.classList.remove('hidde');
         } else {
            if (!this.cidadesBox.classList.contains('hidde')) this.cidadesBox.classList.add('hidde');
         }
         if (bairrosQtd > 0) {
            this.bairrosBox.classList.remove('hidde');
         } else {
            if (!this.bairrosBox.classList.contains('hidde')) this.bairrosBox.classList.add('hidde');
         }
         if (condominiosQtd > 0) {
            this.condominiosBox.classList.remove('hidde');
         } else {
            if (!this.condominiosBox.classList.contains('hidde')) this.condominiosBox.classList.add('hidde');
         }
      }

      if (cidadesQtd + bairrosQtd + condominiosQtd > 0) {
         this.open();
      } else {
         this.close();
      }
   }

   open() {
      if (!this.dropdown.classList.contains('open')) this.dropdown.classList.add('open');
   }

   close() {
      this.dropdown.classList.remove('open');
   }
}
