import { Controller } from 'stimulus';
import {EventEmitter} from '../events/event_emitter';
import ImageEditor from 'tui-image-editor';
import FileSaver from 'file-saver';
import blackTheme from 'tui-image-editor/src/js/ui/theme/theme.js';
import Dropzone from 'dropzone';

export default class extends Controller {
    showClass = "show";

    connect() {
        this.show = this.element.classList.contains(this.showClass);
        this.setShow();
        this.contentBox = this.element.querySelector('.modal-content');
        this.closeButton = this.element.querySelector(".close-modal");

        this.dropzoneElm = this.element.querySelector(".dropzone");

        this.openHandler = this.open.bind(this);
        this.btnsToOpen = document.querySelectorAll(".open-multi-upload");
        this.btnsToOpen?.forEach((v, k) => {
            v.addEventListener("click", (e) => {
                let pathMulti = v.getAttribute("data-path-multi");
                console.log('teste');
                this.openHandler(pathMulti);
            });
        });


        this.clickWinHandler = this.clickOutside.bind(this);
        this.closeButton.addEventListener('click', this.clickWinHandler);
        this.element.addEventListener('click', this.clickWinHandler);

        EventEmitter.subscribe("open-multiupload", this.openHandler);
    }

    open(pathMulti) {
        this.dropzoneElm.setAttribute("data-dropzone-url", pathMulti);
        this.show = true;
        this.setShow();
    }

    initMultiUpload(){
        var previewTemplate = this.dropzoneElm.querySelector('.dz-preview');
        var t = void 0 !== this.dropzoneElm.getAttribute("data-dropzone-multiple");
        var i = void 0;
        var options = { 
            url: this.dropzoneElm.getAttribute("data-dropzone-url"),
            thumbnailWidth: null,
            thumbnailHeight: null,
            previewsContainer: previewTemplate,
            previewTemplate: previewTemplate.innerHTML,
            maxFiles: t ? null : 1,
            acceptedFiles: t ? null : "image/*",
            init: function () {
                this.on("addedfile", function (e) {
                    !t && i && this.removeFile(i), i = e;
                });
                this.on("uploadprogress", function (e, p) {
                    e.previewTemplate.querySelector('.dz-progress').innerHTML = p == 100 ? 'Concluído' : Math.round(p) + '%';
                });
            }
        };
        previewTemplate.innerHTML = "";

        if (Dropzone.instances.length > 0) {
            Dropzone.instances.forEach((e) => {
                e.off();
                e.destroy();
            });
        }
        
        Dropzone.autoDiscover = false;
        var dropzones = new Dropzone(this.dropzoneElm, options);
    }

    clickOutside(e) {
        if (this.show && (!this.contentBox.contains(e.target) || this.closeButton.contains(e.target))) {
            this.show = false;
            this.setShow();
        }
    }

    setShow(){
        if (this.show) {
            this.initMultiUpload();
            //
            this.element.style.display = 'block';
            this.element.classList.add(this.showClass);
            this.element.classList.add('fade');
        } else {
            this.element.classList.remove(this.showClass);
            let modal = this.element;
            modal.style.display = 'none';
        }
    }
}
