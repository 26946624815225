import { Controller } from 'stimulus';

export default class extends Controller {
    connect() {
        this.update();
        let onClickHandler = this.onClick.bind(this);
        this.element.addEventListener('click', onClickHandler);
    }
    onClick(e) {
        let theme = this.getPreference();
        this.change(theme === 'light' ? 'dark' : 'light');
    }
    update() {
        let theme = this.getPreference();
        if (theme === 'dark') {
            document.documentElement.classList.add('dark');
            document.body.style.setProperty('--icon-fill', 'white');
        } else {
            document.documentElement.classList.remove('dark');
            document.body.style.setProperty('--icon-fill', 'black');
        }
        document.firstElementChild.setAttribute('data-theme', theme);
        document.querySelector('#theme-toggle')?.setAttribute('aria-label', theme);
    }
    change(theme = null) {
        if (theme == null) {
            localStorage.removeItem('theme');
            return;
        }
        localStorage.theme = theme; // dark, light
        this.update();
    }
    getPreference() {
        let theme = "light";
        if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
            theme = "dark";
        }
        return theme;
    }
}
