import { Controller } from 'stimulus';
import Swiper from 'swiper';

export default class extends Controller {
    connect() {
        var slider = new Swiper(this.element.querySelector('.swiper'), {
            slidesPerView: 1,
            spaceBetween: 0,
            pagination: {
                clickable: true,
                el: this.element.querySelector('.swiper-pagination'),
            },
        });
    }
}
