import { Controller } from 'stimulus';
import Swiper from 'swiper';

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
   connect() {
      this.swiper = new Swiper(this.element.querySelector('.swiper'), {
         loop: true,
         pagination: {
            el: this.element.querySelector('.swiper-pagination'),
            clickable: true,
         },
      });
   }
}
