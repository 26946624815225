import { Controller } from 'stimulus';

export default class extends Controller {
   connect() {
      this.handler = this.element.querySelector('.sm-handler');
      this.onHandlerClickH = this.onHandlerClick.bind(this);
      this.handler.addEventListener('click', this.onHandlerClickH);

      this.dropdown = this.element.querySelector('.sm-dropdown');
      this.items = this.dropdown.querySelectorAll('.sm-item:not(.sm-clean)');
      this.onItemClickHandler = this.onItemClick.bind(this);
      this.onBtnCleanClickHandler = this.onBtnCleanClick.bind(this);
      this.dropdown.querySelector('.sm-clean').addEventListener('click', this.onBtnCleanClickHandler);
      this.items?.forEach(item => item.addEventListener('click', this.onItemClickHandler));

      this.values = this.element.getAttribute('data-value') ? this.element.getAttribute('data-value').split(',') : [];

      this.onClickOutsideHandler = this.onClickOutside.bind(this);
      this.onKeyUpHandler = this.onKeyUp.bind(this);
      document.addEventListener('click', this.onClickOutsideHandler);
      document.addEventListener('keyup', this.onKeyUpHandler);

      this.updateItemHandler = this.updateItem.bind(this);
      this.updateValues();
   }
   onHandlerClick(e) {
      e.preventDefault();
      
      if (this.dropdown.contains(e.target)) {
         return;
      }

      if (this.element.classList.contains('open')) {
         this.close();
      } else {
         this.element.classList.add('open');
      }
   }
   onClickOutside(e) {      
      if (this.element.contains(e.target)) {
         return;
      }
      this.close();
   }
   onKeyUp(e) {
      if (e.keyCode == 27) {
         this.close();
      }
   }
   close() {
      this.element.classList.remove('open');
   }
   onItemClick(e) {
      let value = e.target.getAttribute('data-value');
      if (this.values.indexOf(value) >= 0) {
         this.values = this.values.filter(_val => _val != value);
      } else {
         this.values.push(value);
      }
      this.updateValues();
   }
   onBtnCleanClick(e) {
      this.values = [];
      this.updateValues();
      this.close();
   }
   updateValues() {
      this.element.setAttribute('data-value', this.values.join(','));
      this.handler.innerHTML = this.values.length + " Selecionado(s)";
      this.items.forEach(item => {
         this.updateItemHandler(item);
      });
   }
   updateItem(item) {
      let i = item.querySelector('i');
      if (this.values.indexOf(item.getAttribute('data-value')) >= 0) {
         i.classList.remove('d-none');
         return;
      }
      if (!i.classList.contains('d-none')) {
         i.classList.add('d-none');
      }
   }
}
