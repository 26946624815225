import { Controller } from 'stimulus';
import Swiper from 'swiper';

export default class extends Controller {
    connect() {
        this.bannerSlider = this.element.querySelector('.swiper');
        this.btnPaginations = this.element.querySelectorAll('.btn-pagination');
        this.slideContents = this.element.querySelectorAll('.slide-content');
        this.labelCurrentIdx = this.element.querySelector('.current-slide-idx');
        this.circleSvgProgress = this.element.querySelector('.slider-progress');
        this.totalSlides = Number(this.element.getAttribute('data-slides'));

        this.btnPaginationClickHandler = this.btnPaginationClick.bind(this);
        this.btnPaginations.forEach(btn => btn.addEventListener('click', this.btnPaginationClickHandler));
        this.slideChangeHandler = this.slideChange.bind(this);
        // this.circleSvgProgress.style.strokeDashoffset = this.calculateProgress();

        this.gallery = new Swiper(this.bannerSlider, {
            spaceBetween: 10,
            slidesPerView: 1,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            on: {
                slideChange: () => this.slideChangeHandler(),
            }
        });
    }
    calculateProgress(idx = 0) {
        return 157.08 - (157.08 * (idx + 1) / this.totalSlides);
    }
    btnPaginationClick(e) {
        let idx = e.target.getAttribute('data-idx');
        this.gallery.slideTo(idx);
    }
    slideChange() {
        let idx = Number(this.gallery.activeIndex);

        this.labelCurrentIdx.innerHTML = idx + 1;

        this.circleSvgProgress.style.strokeDashoffset = this.calculateProgress(idx);

        this.btnPaginations.forEach(btn => {
            if (btn.getAttribute('data-idx') == idx) {
                btn.classList.add('!bg-primary');
            } else {
                btn.classList.remove('!bg-primary');
            }
        });
        this.slideContents.forEach(content => {
            if (content.getAttribute('data-idx') == idx) {
                content.classList.remove('hidden');
            } else {
                content.classList.add('hidden');
            }
        });
    }
}
