import { Controller } from 'stimulus';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

export default class extends Controller {
    connect() {
        this.area = this.element.querySelector(".area-items");

        var slider = new Swiper(this.area, {
            modules: [Navigation],
            slidesPerView: 4,
            spaceBetween: 20,
            navigation: {
                nextEl: this.element.querySelector('.slider-next'),
                prevEl: this.element.querySelector('.slider-prev'),
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                480: {
                    slidesPerView: 2,
                },
                780: {
                    slidesPerView: 3,
                },
                1000: {
                    slidesPerView: 4
                }
            }
        });
    }
}
