import { Controller } from 'stimulus';
import {EventEmitter} from '../events/event_emitter';
import { toast, DragDropOrder } from '../utils'; 
import axios from 'axios';
import init from '../common';

export default class extends Controller {
    showClass = "show";

    connect() {
        this.show = this.element.classList.contains(this.showClass);
        this.setShow();

        this.entity = null;
        this.field = null;
        this.id = null;

        this.contentBox = this.element.querySelector('.modal-content');
        this.closeButton = this.element.querySelector(".close-modal");
        this.headerTitle = this.element.querySelector('.card-header h4');
        this.content = this.element.querySelector('.content-modal');

        this.openHandler = this.open.bind(this);
        this.btnsToOpen = document.querySelectorAll(".open-translation");
        this.btnsToOpen?.forEach((v, k) => {
            v.addEventListener("click", (e) => {
                let entity = e.target.getAttribute("data-entity");
                let field = e.target.getAttribute("data-field");
                let id = e.target.getAttribute("data-id");
                this.openHandler({ entity, field, id });
            });
        });

        this.clickWinHandler = this.clickOutside.bind(this);
        this.closeButton.addEventListener('click', this.clickWinHandler);
        this.element.addEventListener('click', this.clickWinHandler);

        EventEmitter.subscribe("open-translation", this.openHandler);
    }

    open({ entity, field, id }) {
        this.entity = entity;
        this.field = field;
        this.id = id;

        this.show = true;
        this.setShow();
    }

    clickOutside(e) {
        if (this.show && (!this.contentBox.contains(e.target) || this.closeButton.contains(e.target))) {
            this.show = false;
            this.setShow();
        }
    }

    async loadContent() {
        this.headerTitle.innerHTML = "Carregando...";
        this.content.innerHTML = "<span class='loading-bar-modal'></span>";

        const params = new URLSearchParams();
        params.append('entity', this.entity);
        params.append('field', this.field);
        params.append('id', this.id);
        
        await axios.post(`${PATHS.admin_translation_form}`, params).then(({ data }) => {
            let { view, title } = JSON.parse(data);
            if (view != null) {
                this.headerTitle.innerHTML = title;
                this.content.innerHTML = view;
                init();
            } else {
                this.headerTitle.innerHTML = "Erro";
                this.content.innerHTML = "<p class='empty-text'>Erro ao carregar formulário</p>";
            }
        });

    }

    setShow(){
        if (this.show) {
            this.loadContent();
            //
            this.element.style.display = 'block';
            this.element.classList.add(this.showClass);
            this.element.classList.add('fade');
        } else {
            this.element.classList.remove(this.showClass);
            this.element.style.display = 'none';
        }
    }
}
