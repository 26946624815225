import { Controller } from 'stimulus';
import { clamp, getCookie, setCookie } from '../utils';
import { EventEmitter } from '../utils/events';

export default class extends Controller {
    connect() {
        
        this.maxMouseLeaves = 8;
        this.isOpen = false;

        this.leadPopup = this.element.querySelector('.lead-popup');
        this.bgPopup = this.element.querySelector('.bg-popup');
        this.closeTriggers = this.element.querySelectorAll('.close-trigger');

        let openPopupHandler = this.openPopup.bind(this);
        let closePopupHandler = this.closePopup.bind(this);
        let onMouseLeaveWindowHandler = this.onMouseLeaveWindow.bind(this);
        let onLeadCaptureFormSendedHandler = this.onLeadCaptureFormSended.bind(this);
        let onKeyUpWindowHandler = this.onKeyUpWindow.bind(this);

        this.closeTriggers?.forEach(trigger => {
            trigger.addEventListener('click', e => {
                closePopupHandler();
            });
        });

        document.addEventListener("mouseleave", onMouseLeaveWindowHandler);
        document.addEventListener("keyup", onKeyUpWindowHandler);

        EventEmitter.subscribe("form_contato_send", onLeadCaptureFormSendedHandler);
        EventEmitter.subscribe("form_lead_send", onLeadCaptureFormSendedHandler);
        EventEmitter.subscribe("form_lead-popup_send", onLeadCaptureFormSendedHandler);

    }

    onKeyUpWindow(event) {
        if (this.isOpen && event.key === 'Escape') {
            this.closePopup();
        }
    }

    onMouseLeaveWindow(event) {
        if (event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)) {
            
            let mouseLeaveCountCookie = getCookie('lead-leave');
            if (mouseLeaveCountCookie == null || mouseLeaveCountCookie === false) {
                setCookie('lead-leave', 1, 20);
            } else {
                setCookie('lead-leave', clamp(parseInt(mouseLeaveCountCookie) - 1, 1, this.maxMouseLeaves), 20);
            }
            mouseLeaveCountCookie = getCookie('lead-leave');

            let shouldOpenCookie = getCookie('lead');
            if ((shouldOpenCookie == null || shouldOpenCookie == false) && parseInt(mouseLeaveCountCookie) == 1) {
                this.openPopup();
            }
        }
    }

    onLeadCaptureFormSended() {
        setCookie('lead', true, 20);
    }

    openPopup() {
        this.isOpen = true;
        this.leadPopup.classList.remove('opacity-0', '-translate-y-40');
        this.bgPopup.classList.remove('opacity-0');
        this.element.classList.remove('pointer-events-none');
    }

    closePopup() {
        this.isOpen = false;
        this.leadPopup.classList.add('opacity-0', '-translate-y-40');
        this.bgPopup.classList.add('opacity-0');
        this.element.classList.add('pointer-events-none');
        setCookie('lead-leave', this.maxMouseLeaves, 20);
    }
}
