import { Controller } from 'stimulus';
import { Loader, LoaderOptions, Geocoder, Map, MapTypeId } from 'google-maps';
import { getRandomInt } from '../utils';

export default class extends Controller {

   coordsPattern = new RegExp("[0-9][0-9]\\.[0-9][0-9][0-9][0-9][0-9][0-9]", "gim");

   async connect() {
      this.localization = this.element.getAttribute("data-localization") + "";
      const options = {};
      this.loader = new Loader('AIzaSyDTTBVZ0ng2qD9GvryHAamcaEe-Inyjcsw', options);
      this.google = null;

      this.showMap();
   }

   async showMap() {
      if (this.google == null || this.google == undefined) {
         await this.loadGoogle();
      }

      if (this.element.innerHTML == "") {
         if (this.localization.match(this.coordsPattern)) {
            let splitedCoords = this.localization.split(',');
            this.latitude = Number(splitedCoords[0]);
            this.longitude = Number(splitedCoords[1]);
            //
            this.loadMap(this.latitude, this.longitude);
         } else {
            this.codeAddress();
         }
      }
   }

   codeAddress() {
      this.geocoder = new this.google.maps.Geocoder();
      this.onCodeAddressResultsHandle = this.onCodeAddressResults.bind(this);
      this.geocoder.geocode({ 'address': this.localization }, this.onCodeAddressResultsHandle);
   }

   onCodeAddressResults(results, status) {
      if (status == 'OK') {
         let coords = results[0].geometry.location;
         if (coords) {
            this.loadMap(coords.lat(), coords.lng());
         }
      }
   }

   async loadGoogle() {
      this.google = await this.loader.load();
   }

   moveLatAndLongByMeters(latitude, longitude, meters) {
      var earth = 6378.137,
         pi = Math.PI,
         cos = Math.cos,
         m = (1 / ((2 * pi / 360) * earth)) / 1000;
      var new_latitude = latitude + (meters * m);
      var new_longitude = longitude + (meters * m) / cos(latitude * (pi / 180));
      
      return { lat: new_latitude, lng: new_longitude};
   }

   async loadMap(lat, lng) {
      var latLng = this.moveLatAndLongByMeters(lat, lng, getRandomInt(-320, 320));
      var latLngGmaps = new this.google.maps.LatLng(latLng.lat, latLng.lng);

      this.map = new this.google.maps.Map(this.element, {
         center: latLngGmaps,
         zoom: 14,
         zoomControl: true,
         scrollwheel: false,
         mapTypeId: this.google.maps.MapTypeId.ROADMAP,
         disableDefaultUI: false
      });

      const circle = new google.maps.Circle({
         strokeColor: "#EC6730",
         strokeOpacity: 0.8,
         strokeWeight: 2,
         fillColor: "#EC6730",
         fillOpacity: 0.35,
         map: this.map,
         center: latLngGmaps,
         radius: 750,
      });
   }
}
