import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = [ "menu" ];
    showClass = "show";
    
    connect() {
        this.show = this.element.classList.contains(this.showClass);
        this.setShow();
        this.clickWinHandler = this.clickOutside.bind(this);
        window.addEventListener('click', this.clickWinHandler);
    }
    clickOutside(e) {
        if (this.show && !this.element.contains(e.target)) {
            this.show = false;
            this.setShow();
        }
    }
    toggle() {
        this.show = !this.show; 
        this.setShow();
    }
    setShow(){
        if (this.show) {
            this.menuTarget.classList.add(this.showClass);
        } else {
            this.menuTarget.classList.remove(this.showClass);
        }
    }
}