import { Controller } from 'stimulus';
import _ from "lodash";
import Swal from "sweetalert2";
import { toast, DragDropOrder } from '../utils'; 
import axios from 'axios';
import { EventEmitter } from '../utils/events';
import init from '../common';
import loadingSvg from '../../public/img/_layout/loading.svg';

export default class extends Controller {

    connect() {
        this.mainContent = document.querySelector(".main-content");
        this.contextType = this.element.getAttribute("data-context-type");
        this.selectorCheckboxTemplate = '<div class="custom-checkbox-no-text-label custom-control custom-checkbox p-0"><input class="custom-control-input" name="{name}" id="{id}" type="checkbox"><label class="custom-control-label" for="{id}"></label></div>';
        this.selectorAllPhotoTemplate = '<div class="custom-control custom-checkbox p-0"><input class="custom-control-input" name="{name}" id="{id}" type="checkbox"><label class="custom-control-label" for="{id}">Selecionar Todos</label></div>';
        this.actionsBoxTemplate = '<div class="text-center mb-2"><span class="badge">0</span> item(s) selecionados</div><button type="button" class="btn btn-white btn-cancel"><i class="fas fa-times d-inline-block mr-1"></i> Cancelar</button><button type="button" class="btn btn-danger btn-send-save" data-form-name="order"><i class="fas fa-trash-alt d-inline-block mr-1"></i> Deletar</button><button type="button" class="btn btn-primary btn-duplicate" data-form-name="order"><i class="fas fa-clone d-inline-block mr-1"></i> Duplicar</button><button type="button" class="btn btn-primary btn-update" data-form-name="order"><i class="fas fa-edit d-inline-block mr-1"></i> Editar</button>';
        this.batchUpdateTemplate = '<div class="batch-update"><div class="header-bu"><div class="d-flex flex-row align-items-center"><h2><i class="fas fa-edit d-inline-block mr-1"></i>Editar selecionado(s)</h2><span class="qtd"></span></div><button class="btn btn-white btn-close-bu"><i class="fas fa-times d-inline-block mr-1"></i> Fechar</button></div><div class="bu-content"><div class="form-container"></div><img class="loading-bu" src="'+loadingSvg+'"/></div></div>';

        this.selectedIds = [];
        this.open = false;
        this.batchUpdate = null;
        this.actionsButtons = null;

        switch (this.contextType) {
            case "table":
                this.updateTableHtml();
                break;
            case "photo":
                this.updatePhotoHtml();
                break;
            default:
                break;
        }
    }

    updatePhotoHtml() {
        this.entity = this.element.querySelector(".BlockLayout").getAttribute("data-entity");
        if (this.entity == null || this.entity == undefined) {
            console.warn("Batch Operations Controller: Nenhum namespace de entity encontrado em questão! Adicione um atributo data-entity em .BlockLayout com um namespace.");
            return;
        }
        //
        let headOptions = this.element.querySelector(".head-options");
        let newSelectorHead = this.createElementFromHTML(this.selectorAllPhotoTemplate.replaceAll("{id}", "newSelectorHead").replaceAll("{name}", "selector--all"));
        newSelectorHead.classList.add("batch-operations-selector");
        headOptions.prepend(newSelectorHead);
        this.selectorAllBind = this.selectorAllChanged.bind(this);
        this.selectorAllInput = newSelectorHead.querySelector("input");
        this.selectorAllInput.addEventListener("change", this.selectorAllBind);
        // items
        this.selectorItemBind = this.selectorItemChanged.bind(this);
        this.rows = this.element.querySelectorAll(".photo-item");
        this.rows?.forEach((row, k) => {
            let newSelectorBodyItem = document.createElement("div");
            let id = "newSelectorBodyItem-" + (k + 1);
            let name = "selector--" + row.getAttribute("data-id");
            newSelectorBodyItem.innerHTML = this.selectorCheckboxTemplate.replaceAll("{id}", id).replaceAll("{name}", name);
            newSelectorBodyItem.classList.add("selector-item");
            row.prepend(newSelectorBodyItem);
            newSelectorBodyItem.querySelector("input")?.addEventListener("change", this.selectorItemBind);
        });
    }

    updateTableHtml() {
        this.entity = this.element.querySelector("tbody")?.getAttribute("data-entity");
        if (this.entity == null || this.entity == undefined) {
            console.warn("Batch Operations Controller: Nenhum namespace de entity encontrado em questão! Adicione um atributo data-entity na tag tbody com um namespace.");
            return;
        }
        //
        let headTable = this.element.querySelector("thead tr");
        let newSelectorHead = document.createElement("th");
        newSelectorHead.setAttribute("scope", "col");
        newSelectorHead.innerHTML = this.selectorCheckboxTemplate.replaceAll("{id}", "newSelectorHead").replaceAll("{name}", "selector--all");
        newSelectorHead.classList.add("batch-operations-selector");
        headTable.prepend(newSelectorHead);
        this.selectorAllBind = this.selectorAllChanged.bind(this);
        this.selectorAllInput = newSelectorHead.querySelector("input");
        this.selectorAllInput.addEventListener("change", this.selectorAllBind);
        // items
        this.selectorItemBind = this.selectorItemChanged.bind(this);
        this.rows = this.element.querySelectorAll("tbody tr");
        this.rows?.forEach((row, k) => {
            let newSelectorBodyItem = document.createElement("td");
            let id = "newSelectorBodyItem-" + (k + 1);
            let name = "selector--" + row.getAttribute("data-id");
            newSelectorBodyItem.innerHTML = this.selectorCheckboxTemplate.replaceAll("{id}", id).replaceAll("{name}", name);
            newSelectorBodyItem.classList.add("selector-item");
            row.prepend(newSelectorBodyItem);
            newSelectorBodyItem.querySelector("input")?.addEventListener("change", this.selectorItemBind);
        });
    }

    selectorAllChanged(e) {
        console.log("chnge all");
        let targetChecked = e.target.checked;
        this.selectorAll(targetChecked);
    }

    selectorAll(select = true, changeSelectorAll = false) {
        if (changeSelectorAll) {
            this.selectorAllInput.checked = select;
        }
        let selectors = this.element.querySelectorAll(".selector-item");
        selectors?.forEach((selectorBox, k) => {
            let selector = selectorBox.querySelector("input");
            selector.checked = select;
            selector.dispatchEvent(new Event('change'));
        });
    }

    selectorItemChanged(e) {
        this.updateSelecteds(e.target);
        if (this.selectedIds.length > 0) {
            this.updateQtd();
            if (!this.open) this.showActionsButtons();
            if (this.batchUpdate != null) this.createForm();
        } else {
            if (this.open) {
                this.onCancel(null);
            }
        }
    }

    updateSelecteds(selector, k = null) {
        if (selector == null) return;
        let id = selector.name.split("--")[1];
        console.log(id);
        let index = this.selectedIds.indexOf(id);

        if (selector.checked) {
            if (index == -1) {
                this.selectedIds.push(id);
            }
        } else {
            if (index !== -1) {
                this.selectedIds.splice(index, 1);
            }
        }
    }

    showActionsButtons(recreate = false) {
        if (this.actionsButtons == null || recreate === true) {
            let actionsButtons = document.createElement('div');
            actionsButtons.classList += 'batch-actions-buttons';
            actionsButtons.innerHTML = this.actionsBoxTemplate;
            this.actionsButtons = document.body.insertAdjacentElement('beforeend', actionsButtons);
            // events
            this.onDeleteHandler = this.onDelete.bind(this);
            this.onCancelHandler = this.onCancel.bind(this);
            this.onDuplicateHandler = this.onDuplicate.bind(this);
            this.onUpdateHandler = this.onUpdate.bind(this);
            this.actionsButtons.querySelector('.btn-send-save').addEventListener('click', this.onDeleteHandler);
            this.actionsButtons.querySelector('.btn-cancel').addEventListener('click', this.onCancelHandler);
            this.actionsButtons.querySelector('.btn-duplicate').addEventListener('click', this.onDuplicateHandler);
            this.actionsButtons.querySelector('.btn-update').addEventListener('click', this.onUpdateHandler);
            // animate show
            let aB = this.actionsButtons;
            this.open = true;
            setTimeout(() => {
                aB.classList.add('show');
            }, 50);
        }

        this.updateQtd();
    }

    updateQtd() {
        if (this.actionsButtons) {
            this.actionsButtons.querySelector('.badge').innerHTML = this.selectedIds.length;
        }
        if (this.batchUpdate) {
            this.batchUpdate.querySelector('.header-bu .qtd').innerHTML = this.selectedIds.length;
        }
    }

    hideActionsButtons(onlyAnimation = false) {
        this.actionsButtons.classList.remove("show");
        if (!onlyAnimation) {
            this.open = false;
            this.actionsButtons.remove();
            this.actionsButtons = null;
        }
    }

    createElementFromHTML(htmlString) {
        var div = document.createElement('div');
        div.innerHTML = htmlString.trim();

        return div.firstChild; 
    }

    getBatchParams() {
        const params = new URLSearchParams();
        params.append('entity', this.entity);
        this.selectedIds.forEach((id, k) => {
            params.append('ids['+k+']', id);
        });

        return params;
    }

    onCancel(e) {
        this.hideActionsButtons();
        this.selectorAll(false, true);
        this.closeBatchUpdate();
    }
    onDelete(e) {
        if (this.selectedIds != null && this.selectedIds.length == 0) {
            return;
        }

        Swal.fire({
            title: 'Você tem certeza que deseja excluir ' + this.selectedIds.length + ' item(s)?',
            text: "Não será possível reverter esta operação!",
            type: 'warning',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, excluir!'
        }).then((result) => {
            if (result.value) {
                e.target.classList.add('loading');
                e.target.disabled = true;
                try {
                    const params = this.getBatchParams();

                    axios.post(`${PATHS.admin_batch_delete}`, params).then(data => {
                        e.target.classList.remove('loading');
                        e.target.disabled = false;
                        if (data.status == false) {
                            toast({
                                type: 'error',
                                title: 'Ocorreu um erro!'
                            });
                        } else {
                            Swal.fire(
                                'Deletado(s)!',
                                'Item(s) excluído(s) com sucesso.',
                                'success'
                            ).then((result) => {
                                window.location.reload();
                            });
                        }
                    });
                } catch (error) {
                    e.target.classList.remove('loading');
                    e.target.disabled = false;
                    toast({
                        type: 'error',
                        title: 'Ocorreu um erro'
                    });
                }
            }
        });
    }
    onDuplicate(e) {
        if (this.selectedIds != null && this.selectedIds.length == 0) {
            return;
        }

        Swal.fire({
            title: 'Você tem certeza que deseja duplicar ' + this.selectedIds.length + ' item(s)?',
            text: "Não será possível reverter esta operação!",
            type: 'warning',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, duplicar!'
        }).then((result) => {
            if (result.value) {
                e.target.classList.add('loading');
                e.target.disabled = true;
                try {
                    const params = this.getBatchParams();

                    axios.post(`${PATHS.admin_batch_duplicate}`, params).then(data => {
                        e.target.classList.remove('loading');
                        e.target.disabled = false;
                        if (data.status == false) {
                            toast({
                                type: 'error',
                                title: 'Ocorreu um erro!'
                            });
                        } else {
                            Swal.fire(
                                'Duplicado(s)!',
                                'Item(s) duplicado(s) com sucesso.',
                                'success'
                            ).then((result) => {
                                window.location.reload();
                            });
                        }
                    });
                } catch (error) {
                    e.target.classList.remove('loading');
                    e.target.disabled = false;
                    toast({
                        type: 'error',
                        title: 'Ocorreu um erro'
                    });
                }
            }
        });
    }
    async onUpdate(e) {
        this.loadBatchUpdate();
        this.hideActionsButtons(true);
        //
        this.createForm();
    }

    async createForm() {
        const formContainer = this.batchUpdate?.querySelector(".form-container")
        if (formContainer) {
            formContainer.innerHTML = "";
            let html = await this.loadForm();
            formContainer.innerHTML = html;
            //
            let formEl = this.batchUpdate.querySelector("form");
            if (formEl != null) {
                formEl.action = PATHS.admin_batch_form_action;
                formEl.name = 'admin_batch_form_action';
                let buttonSave = document.createElement('button');
                buttonSave.classList = "btn btn-primary btn-send-form";
                buttonSave.setAttribute("data-form-name", formEl.name);
                buttonSave.innerHTML = "Salvar";
                formEl.append(buttonSave);
                // add ids selecteds to batch update
                let section = formEl.querySelector("section.form-area-to-send");
                if (this.selectedIds) {
                    this.selectedIds.forEach((id, k) => {
                        let inputId = document.createElement('input');
                        inputId.name = "ids[]";
                        inputId.type = "hidden";
                        inputId.value = id;
                        section.append(inputId);
                    });
                }
                let inputEntity = document.createElement('input');
                inputEntity.name = "entity";
                inputEntity.type = "hidden";
                inputEntity.value = this.entity;
                section.append(inputEntity);
            }
            init();
        }
    }

    async loadForm() {
        const params = this.getBatchParams();
        let html = "<p class='empty-text'>Erro ao carregar formulário</p>";
        this.batchUpdate.querySelector('.loading-bu').classList.add("show");
        
        await axios.post(`${PATHS.admin_batch_form}`, params).then(({ data }) => {
            let { view, title } = JSON.parse(data);
            if (view != null) {
                html = view;
                // this.batchUpdate.querySelector(".header-bu i").innerHTML = title;
            }
        });

        this.batchUpdate.querySelector('.loading-bu').classList.remove("show");
        return html;
    }
    loadBatchUpdate() {
        this.mainContent.classList.add("batch-update-open");
        if (this.batchUpdate == null) {
            this.batchUpdate = document.body.insertAdjacentElement('beforeend', this.createElementFromHTML(this.batchUpdateTemplate));
            this.onCloseBatchUpdateHandler = this.onCloseBatchUpdate.bind(this);
            this.batchUpdate.querySelector(".btn-close-bu").addEventListener("click", this.onCloseBatchUpdateHandler);
            this.updateQtd();
        }
    }
    onCloseBatchUpdate(e) {
        this.closeBatchUpdate();
        this.showActionsButtons(true);
    }
    closeBatchUpdate() {
        if (this.batchUpdate != null) {
            this.batchUpdate.remove();
            this.batchUpdate = null;
            this.mainContent.classList.remove("batch-update-open");
        }
    }
}
