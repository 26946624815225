import { Controller } from 'stimulus';
import PhotoSwipeLightbox from "photoswipe/src/js/lightbox/lightbox";
import PhotoSwipe from 'photoswipe/src/js/photoswipe';
// import 'photoswipe/src/photoswipe.css';

export default class extends Controller {
   connect() {
      const options = {
         gallery: this.element,
         children: 'a',
         showHideAnimationType: 'zoom',
         loop: false,
         initialZoomLevel: 0.85,
         pswpModule: () => PhotoSwipe
      };
      const lightbox = new PhotoSwipeLightbox(options);
      lightbox.on('uiRegister', function () {
         lightbox.pswp.ui.registerElement({
            name: 'contact-button',
            order: 9,
            isButton: true,
            html: 'Gostou deste imóvel? Entre em contato.',
            onClick: (event, el) => {
               window.open(WHATSAPP_LINK);
            }
         });
      });
      lightbox.init();
   }
}
