import { Controller } from 'stimulus';

export default class extends Controller {
    retractedClass = "retracted";
    retractedKey = "menu_retracted";

    initialize() {
        this.retracted = String(localStorage.getItem(this.retractedKey)).toLowerCase() == "true" ?? false;
        this.menu = document.getElementById("sidenav-main");
        this.pageBody = document.getElementById("page-body");
        this.logoBox = document.getElementById("main-logo");
        this.setClass();
    }
    toggle() {
        this.retracted = !this.retracted;
        this.setClass();
        this.updateLocalStorage();
    }
    setClass(){
        if (this.retracted) {
            this.element.classList.add('closed');
            this.menu.classList.add(this.retractedClass);
            this.pageBody.classList.add(this.retractedClass);
            this.logoBox.classList.add(this.retractedClass);
        } else {
            this.element.classList.remove('closed');
            this.menu.classList.remove(this.retractedClass);
            this.pageBody.classList.remove(this.retractedClass);
            this.logoBox.classList.remove(this.retractedClass);
        }
    }
    updateLocalStorage(){
        localStorage.setItem(this.retractedKey, this.retracted);
    }
}