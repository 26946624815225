import { Controller } from 'stimulus';
import simpleParallax from 'simple-parallax-js';
import { sizeOfThings } from '../utils';

export default class extends Controller {
    connect() {
        new simpleParallax(this.element, {
            scale: 1.3,
            overflow: true,
            orientation: 'down'
        });
    }
}