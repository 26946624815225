import { Controller } from 'stimulus';
import loadingPanel from '../../public/img/_layout/loading_panel.svg';

export default class extends Controller {
    showClass = "show";
    
    connect() {
        this.url = this.element.getAttribute('data-url');
        this.show = this.element.classList.contains(this.showClass);
        this.title = this.element.getAttribute('title');
        
        let modalHtml = '<div class="modal-dialog modal-xl">'+
                            '<div class="modal-content">'+
                                '<div class="modal-header">'+
                                    '<h5 class="modal-title h4">'+ this.title +'</h5>'+
                                    '<button type="button" class="close-modal">'+
                                        '<span aria-hidden="true">×</span>'+
                                    '</button>'+
                                '</div>'+
                                '<img class="d-block py-3" src="'+ loadingPanel +'"/>'+
                            '</div>'+
                        '</div>';
        let modalElement = document.createElement('div');
        modalElement.classList += 'modal fade';
        modalElement.setAttribute('role', 'dialog');
        modalElement.setAttribute('aria-hidden', 'true');
        modalElement.innerHTML = modalHtml;
        
        this.modal = document.body.insertAdjacentElement('beforeend', modalElement);
        this.contentBox = this.modal.querySelector(".modal-content");
        this.closeButton = this.modal.querySelector(".close-modal");
        
        this.setShow();
        this.clickWinHandler = this.clickOutside.bind(this);
        this.closeButton.addEventListener('click', this.clickWinHandler);
        this.modal.addEventListener('click', this.clickWinHandler);
    }
    loadContent() {
        // this.contentBox.innerHTML = loadingPanel;
    }
    clickOutside(e) {
        if (this.show && (!this.contentBox.contains(e.target) || this.closeButton.contains(e.target))) {
            this.show = false;
            this.setShow();
        }
    }
    toggle() {
        this.show = !this.show; 
        this.setShow();
        this.loadContent();
    }
    setShow(){
        if (this.show) {
            this.modal.classList.add(this.showClass);
            this.modal.style.display = 'block';
        } else {
            this.modal.classList.remove(this.showClass);
            let modal = this.modal;
            this.modal.addEventListener('transitionend', function() {
                modal.style.display = 'none';
                // modal.removeEventListener('transitionend', setDisplayNone);
            });
        }
    }
}