import { Controller } from 'stimulus';

export default class extends Controller {
    connect() {
        this.links = this.element.querySelectorAll('.tab-link');
        this.contents = this.element.querySelectorAll('.tab-content');

        this.onLinkClickHandler = this.onLinkClick.bind(this);
        this.links.forEach(link => link.addEventListener('click', (e) => this.onLinkClickHandler(link)));
    }
    onLinkClick(link) {
        var contentKey = link.getAttribute('data-tab-target');
        this.links.forEach(_link => {
            var key = _link.getAttribute('data-tab-target');
            if (key == contentKey) {
                _link.classList.add('text-primary', 'hover:text-primarydark', '!border-primary', 'hover:bg-primary/[.18]');
            } else {
                _link.classList.remove('text-primary', 'hover:text-primarydark', '!border-primary', 'hover:bg-primary/[.18]');
            }
        });
        this.contents.forEach(content => {
            var key = content.getAttribute('data-tab-content');
            if (key == contentKey) {
                content.classList.remove('hidden');
            } else {
                content.classList.add('hidden');
            }
        });
    }
}
