import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = [ "menu" ];
    showClass = "show";
    
    connect() {
        this.clickWinHandler = this.click.bind(this);
        this.element.addEventListener('click', this.clickWinHandler);

    }
    click() {
        this.element.classList.toggle("item-active");
    }
}