import { Controller } from 'stimulus';
import loadingPanel from '../../public/img/_layout/loading_panel.svg';

export default class extends Controller {
    showClass = "show";
    
    connect() {
        
        this.modal = document.querySelector('#modal-report-imoveis');
        this.show = this.modal.classList.contains(this.showClass);
        this.contentBox = this.modal.querySelector(".modal-content");
        this.closeButton = this.modal.querySelectorAll(".close-modal");
        this.marcarTodos = this.modal.querySelector('#customCheck-marcar');
        this.marcarTodos.addEventListener('change', e => {
            let props = this.modal.querySelectorAll('.prop-entity');
            let checkedAll = this.marcarTodos.checked;
            props.forEach(prop => {
                prop.checked = checkedAll;
            });
        });
        
        // this.setShow();
        this.clickWinHandler = this.clickOutside.bind(this);
        this.clickOpenButton = this.toggle.bind(this);
        this.element.addEventListener('click', this.clickOpenButton);
        this.closeButton.forEach(item => {
            item.addEventListener('click', this.clickWinHandler);
        });
        this.modal.addEventListener('click', this.clickWinHandler);
    }
    loadContent() {
        // this.contentBox.innerHTML = loadingPanel;
    }
    clickOutside(e) {
        let closeButtonContains = false;
        this.closeButton.forEach(btn => { if (btn.contains(e.target)) closeButtonContains = true; });
        if (this.show && (!this.contentBox.contains(e.target) || closeButtonContains)) {
            this.show = false;
            this.setShow();
        }
    }
    toggle() {
        this.show = true; 
        this.setShow();
        this.loadContent();
    }
    setShow(){
        let modal = this.modal;
        if (this.show) {
            document.querySelector('body').style.overflowY = 'hidden';
            this.modal.style.display = 'block';
            setTimeout(() => {
                modal.classList.add(this.showClass);
            }, 100);
        } else {
            this.modal.transition = 'none';
            this.modal.webkitTransition = 'none';
            this.modal.classList.remove(this.showClass);
            const onTransitionEnd =  function(e) {
                if (modal == e.target) {
                    modal.style.display = 'none';
                    document.querySelector('body').style.overflowY = 'scroll';
                    modal.removeEventListener('transitionend', onTransitionEnd);
                }
            };
            this.modal.addEventListener('transitionend', onTransitionEnd);
        }
    }
}