import axios from 'axios';
import { toast } from '../utils'; 
import _ from 'lodash';

export function refresh(data) {
    let boxItems = document.querySelector('.box-itens-fav');
    let favEmpty = document.querySelector('.fav-empty');
    boxItems.innerHTML = "";
    if (data) {
        if (favEmpty) favEmpty.style.display = 'none';
        boxItems.innerHTML = data;
    } else {
        if (favEmpty) favEmpty.style.display = 'block';
    }
}

export function action(id, action, callback) {
    if (action == "" || action == null) {
        action = "toggle";
    }

    try {
        const params = new URLSearchParams();
        params.append('action', action);
        params.append('id', id);

        document.querySelectorAll('.btn-float-fav')?.forEach(btn => {
            btn.classList.add('loading');
        });

        axios.post(`${URL_FAVORITOS}`, params).then(result => {
            document.querySelectorAll('.btn-float-fav')?.forEach(btn => {
                btn.classList.remove('loading');
            });
            callback(result.data);
        });
    } catch (error) {
        // toast({
        //     type: 'error',
        //     title: 'Ocorreu um erro'
        // });
    }
}