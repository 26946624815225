import Swal from "sweetalert2";
import { Swappable, Sortable, Plugins } from '@shopify/draggable';

export function DragDropOrder(containers = null, onDragStop, sortable = false) {

    if (containers == null || (containers && containers.length === 0)) {
        containers = document.querySelectorAll('.BlockLayout');
    }
    if (containers.length === 0) {
        return false;
    }

    let swappable;
    console.log(sortable);
    if (sortable) {
        swappable = new Sortable(containers, {
            draggable: '.Block--isDraggable',
            mirror: {
                appendTo: '.BlockLayout',
                constrainDimensions: true,
            },
        });
    } else {
        swappable = new Swappable(containers, {
            draggable: '.Block--isDraggable',
            mirror: {
                constrainDimensions: true,
            },
            plugins: [Plugins.ResizeMirror],
        });
    }

    swappable.on('drag:stop', onDragStop);

    return swappable;
}

export function toast(options) {
    const finalOptions = { toast: true, position: 'top-end', showConfirmButton: false, timer: 3000 };
    console.log(finalOptions);
    const Toast = Swal.mixin(finalOptions);
    Toast.fire(options.title, '', options.type);
}

export function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export function copyTextToClipboard(text, notification) {
    var textArea = document.createElement("textarea");
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = 0;
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        toast({ type: 'success', position: 'bottom-center', title: notification });
    } catch (err) {
    }
    document.body.removeChild(textArea);
}

export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function stringToSlug(str) {
    str = str.replace(/^\s+|\s+$/g, '');
    str = str.toLowerCase();
    var from = "ãàáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }
    str = str.replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-');
    return str;
}

export function sizeOfThings() {
    var windowWidth = window.innerWidth;
    var windowHeight = window.innerHeight;
    var screenWidth = screen.width;
    var screenHeight = screen.height;
    return [windowWidth, windowHeight];
}

function resizeImage(base64Str, maxWidth = 400, maxHeight = 350) {
    return new Promise(function (resolve) {
        var img = new Image();
        img.src = base64Str;
        img.onload = function () {
            var canvas = document.createElement('canvas');
            var MAX_WIDTH = maxWidth;
            var MAX_HEIGHT = maxHeight;
            var width = img.width;
            var height = img.height;

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }
            canvas.width = width;
            canvas.height = height;
            var ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);
            resolve(canvas.toDataURL().split(';base64,')[1]);
        }
    });
}

function getBase64(file) {
    return new Promise(function (resolve) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            resolve(reader.result);
        };
        reader.onerror = function (error) {
            
        };
    });
 }

export const convertImages = (query, callback) => {
    const images = document.querySelectorAll(query);

    images.forEach(image => {
        fetch(image.src)
            .then(res => res.text())
            .then(data => {
                const parser = new DOMParser();
                const svg = parser.parseFromString(data, 'image/svg+xml').querySelector('svg');

                if (image.id) svg.id = image.id;
                if (image.className) svg.classList = image.classList;

                image.parentNode.replaceChild(svg, image);
            })
            .then(callback)
            .catch(error => console.error(error))
    });
}

export function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
export function eraseCookie(name) {   
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function clamp (num, min, max) { 
    return Math.min(Math.max(num, min), max); 
}

export function fixedEncodeURIComponent(str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16);
    });
}

async function resizeWidthFile(file) {
    if (file) {
        if (file.type.indexOf("image") !== -1) {
            var result64 = await getBase64(file);
            var result = await resizeImage(result64, 1800, 2000);
            var result = b64toBlob(result, file.type);
            var _file = new File([result], file.name, { type: file.type });
            console.log(_file);
            return _file;
        }
    }
    return file;
}

// ------------------------------- FORM UTILS ---------------------------------------
export async function serializeForm(serializableAreas) {
    const formData = new FormData();
    if (serializableAreas) {
        for (var i = 0; i < serializableAreas.length; i++) {
            var serializableArea = serializableAreas[i];
            let fields = serializableArea.querySelectorAll("input:not(.ignore-form), textarea:not(.ignore-form), select:not(.ignore-form)");
            for (var i_field = 0; i_field < fields.length; i_field++) {
                var field = fields[i_field];

                if (field.getAttribute("type") == "checkbox" && field.getAttribute("name").indexOf('[]') !== -1 && field.getAttribute('data-group')) {
                    let group = field.getAttribute("data-group");
                    let value = "";
                    document.querySelectorAll("input[data-group=" + group + "]:checked").forEach(ele => {
                        value += (value.length > 0 ? ',' : '') + ele.value;
                    });
                    formData.append(field.getAttribute('name').replace('[]', ''), (value != undefined) ? value : '0');
                } else if (field.getAttribute("type") == "radio" || field.getAttribute("type") == "checkbox") {
                    let value = document.querySelector('input[name=' + field.getAttribute('name') + ']:checked')?.value;
                    formData.append(field.getAttribute("name"), (value != undefined) ? value : null);
                } else if (field.getAttribute("type") == "file") {
                    if (field.getAttribute("name").indexOf('[]') !== -1) {
                        for (var i_file = 0; i_file < field.files.length; i_file++) {
                            var file = await resizeWidthFile(field.files[i_file]);
                            formData.append(field.getAttribute("name"), file);
                        }
                    } else {
                        var value = field.files[0];
                        if (field.getAttribute('data-src')) {
                            let base64StringType = /,(.+)/.exec(field.getAttribute('data-src'));
                            let blob = b64toBlob(base64StringType[1], base64StringType[0]);
                            let file = new File([blob], field.files[0].name + "_edited.png");
                            value = file;
                        }
                        value = await resizeWidthFile(value);
                        formData.append(field.getAttribute("name"), (value != undefined) ? value : null);
                    }
                } else {
                    let value = field.value;
                    if (value) {
                        if (field.getAttribute("name") == "preco" || field.getAttribute("name") == "valor" || field.classList.contains("money-input")) {
                            value = parseFloat(value.replace('R$ ', '').replaceAll('.', '').replace(',', '.'));
                        } else if (field.getAttribute("name") == "cardNumero") {
                            valor = valor.replace(/ /g, '').replace(/_/g, '');
                        }
                        formData.append(field.getAttribute("name"), value);
                    }
                }
            }
        }
    }
    return formData;
}

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

export function disabledInputs(serializableAreas) {
    if (serializableAreas) {
        serializableAreas.forEach(serializableArea => {
            let fields = serializableArea.querySelectorAll("input, textarea, select");
            fields.forEach(field => {
                if (field.getAttribute("type") != "checkbox" && field.getAttribute("type") !== "hidden") {
                    field.parentNode.classList.add("input-disabled");
                }
                field.disabled = true;
            });
        });
    }
}

export function enableInputs(serializableAreas) {
    if (serializableAreas) {
        serializableAreas.forEach(serializableArea => {
            let fields = serializableArea.querySelectorAll("input, textarea, select");
            fields.forEach(field => {
                if (field.getAttribute("type") != "checkbox" && field.getAttribute("type") !== "hidden") {
                    field.parentNode.classList.remove("input-disabled");
                }
                field.disabled = false;
            });
        });
    }
}

export function applyErrors(serializableAreas, erros) {
    if (erros) {
        console.log(erros);
        // erros.forEach((erroField, index) => {
        Object.entries(erros).forEach(([erroField, index]) => {
            if (serializableAreas) {
                // apply error classes
                serializableAreas.forEach(serializableArea => {
                    let field = serializableArea.querySelector("input[name=" + erroField + "], select[name=" + erroField + "], textarea[name=" + erroField + "]");
                    if (field.getAttribute("type") == "file") {
                        field.parentNode.parentNode.parentNode.classList.add("input-erro-bottom");
                    } else {
                        field.parentNode.parentNode.classList.add('input-erro-bottom');
                    }
                });
                // show all error msgs fields
                var msgsArray = [];
                Object.entries(index).forEach(([k, val]) => {
                    msgsArray.push(val);
                });
                var msgs = msgsArray.join('. ');
                serializableAreas.forEach(serializableArea => {
                    let field = serializableArea.querySelector("input[name=" + erroField + "], select[name=" + erroField + "], textarea[name=" + erroField + "]");
                    if (field.getAttribute("type") == "file") {
                        field.parentNode.parentNode.parentNode.querySelector("erromsg").innerHTML = msgs;
                    } else {
                        field.parentNode.parentNode.querySelector("erromsg").innerHTML = msgs;
                    }
                });
                // events for clear errors
                serializableAreas.forEach(serializableArea => {
                    let inputs = serializableArea.querySelectorAll('input[name=' + erroField + '], textarea[name=' + erroField + ']');
                    inputs.forEach(elem => {
                        elem.addEventListener('keyup', e => {
                            elem.parentNode.parentNode.classList.remove('input-erro-bottom');
                        });
                    });
                    //
                    let selects = serializableArea.querySelectorAll('select[name=' + erroField + '], input[type=datetime-local][name=' + erroField + '], input[type=datetime][name=' + erroField + ']');
                    selects.forEach(elem => {
                        elem.addEventListener('change', e => {
                            elem.parentNode.parentNode.classList.remove('input-erro-bottom');
                        });
                    });
                    //
                    let files = serializableArea.querySelectorAll('input[type=file][name=' + erroField + ']');
                    files.forEach(elem => {
                        elem.addEventListener('change', e => {
                            elem.parentNode.parentNode.parentNode.classList.remove('input-erro-bottom');
                        });
                    });
                });
            }
        });
    }
}


export function imageUploadHandler(blobInfo, success, failure, progress) {
    var xhr, formData;

    xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open('POST', PATHS.image_upload);

    xhr.upload.onprogress = function (e) {
        progress(e.loaded / e.total * 100);
    };

    xhr.onload = function () {
        var json;

        if (xhr.status === 403) {
            failure('HTTP Error: ' + xhr.status, { remove: true });
            return;
        }

        if (xhr.status < 200 || xhr.status >= 300) {
            failure('HTTP Error: ' + xhr.status);
            return;
        }

        json = JSON.parse(xhr.responseText);

        if (!json || typeof json.location != 'string') {
            failure('Invalid JSON: ' + xhr.responseText);
            return;
        }

        success(json.location);
    };

    xhr.onerror = function () {
        failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
    };

    formData = new FormData();
    formData.append('img', blobInfo.blob(), blobInfo.filename());
    formData.append('_token', IMAGE_EDITOR_UPLOAD_TOKEN);

    xhr.send(formData);
}