import { Controller } from 'stimulus';
import { fixedEncodeURIComponent } from '../utils';

export default class extends Controller {

   connect() {
      this.onButtonClickHandler = this.onClick.bind(this);
      this.element.addEventListener('click', this.onButtonClickHandler);
   }

   onClick(e) {
      let table_query = this.element.getAttribute('data-table-query');
      this.generate(table_query);
   }

   generate(table_query, separator = ';', makeDownload = true) {
      // Select rows from table_id
      var rows = document.querySelectorAll('table' + table_query + ' tr');
      // Construct csv
      var csv = [];
      for (var i = 0; i < rows.length; i++) {
         var row = [], cols = rows[i].querySelectorAll('td, th');
         for (var j = 0; j < cols.length; j++) {
            // Clean innertext to remove multiple spaces and jumpline (break csv)
            var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
            // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
            data = data.replace(/"/g, '""');
            // Push escaped string
            row.push('"' + data.replace(/<[^>]*>?/gm, '') + '"');
         }
         csv.push(row.join(separator));
      }
      var csv_string = csv.join('\n');
      // Download it
      var filename = 'export_' + table_query.replace('.', '').replace('#', '') + '_' + new Date().toLocaleDateString() + '.csv';
      if (makeDownload) this.download(csv_string, filename);
   }

   download(csv_string, filename) {
      var link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=UTF-8,' + encodeURI(csv_string));
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
   }
}
