import { Controller } from 'stimulus';

export default class extends Controller {
   connect() {
      this.container = this.element.parentElement;
      this.selectTipo = this.container.querySelector("select[name=tipo]");
      this.checkboxesCaracs = this.container.querySelectorAll("input[data-group=caracteristicas-checkbox]");
      this.caracteristicasData = JSON.parse(this.element.getAttribute("data-caracteristicas"));
      this.onTipoChangeHandler = this.onTipoChange.bind(this);
      this.selectTipo.addEventListener('change', this.onTipoChangeHandler);
      this.searchInput = null;

      this.configCheckboxesSize();
      this.update();
      this.addSearch();
   }

   addSearch() {
      let formGroup = this.checkboxesCaracs[0].parentElement.parentElement;
      let label = formGroup.querySelector(".label-textarea");
      let searchEl = document.createElement("input");
      searchEl.name = "__search_carac";
      searchEl.classList.add("ignore-form");
      searchEl.placeholder = "Pesquise nas características abaixo";

      this.searchInput = formGroup.insertBefore(searchEl, label.nextSibling);
      this.onSearchKeyUpHandler = this.onSearchKeyUp.bind(this);
      this.searchInput.addEventListener("keyup", this.onSearchKeyUpHandler);
   }

   onSearchKeyUp(e) {
      this.update();
   }

   configCheckboxesSize() {
      this.checkboxesCaracs.forEach(checkbox => {
         checkbox.parentElement.classList.add("col-6");
         checkbox.parentElement.classList.add("col-md-2");
      });
   }

   onTipoChange(e) {
      this.update();
   }

   checkMatchWithSearch(checkboxCarac) {
      if (this.searchInput == undefined) {
         return true;
      }
      let label = checkboxCarac.parentElement.querySelector("label");
      if (this.searchInput.value == "" || label.innerHTML.toLowerCase().indexOf(this.searchInput.value.toLowerCase()) !== -1) {
         return true;
      }
      return false;
   }

   update() {
      let curTipo = this.selectTipo.options[this.selectTipo.selectedIndex];
      for (let i = 0; i < this.checkboxesCaracs.length; i++) {
         let checkboxCarac = this.checkboxesCaracs[i];

         let matchWithSearch = this.checkMatchWithSearch(checkboxCarac);

         if (!matchWithSearch) {
            checkboxCarac.parentElement.style.display = "none";
            continue;
         }
         if (curTipo.value == 0) {
            checkboxCarac.parentElement.style.display = "inline-block";
            continue;
         }
         if (curTipo.value == this.getTipoCarac(checkboxCarac.value)) {
            checkboxCarac.parentElement.style.display = "inline-block";
         } else {
            checkboxCarac.parentElement.style.display = "none";
         }
      }
   }

   getTipoCarac(caracId) {
      return this.caracteristicasData.reduce(
         (acc, value) => (value.id == caracId) ? value.tipo : acc,
         null
      );
   }
}
