import { Controller } from 'stimulus';
import { EventEmitter } from '../utils/events';

export default class extends Controller {
    connect() {
        this.leadCapturedBox = this.element.querySelector('.lead-captured-box');
        this.leadCaptureFormBox = this.element.querySelector('.lead-capture-form-box');

        let onLeadCaptureFormSendedHandler = this.onLeadCaptureFormSended.bind(this);

        // EventEmitter.subscribe("form_contato_send", onLeadCaptureFormSendedHandler);
        // EventEmitter.subscribe("form_lead-popup_send", onLeadCaptureFormSendedHandler);
        EventEmitter.subscribe("form_lead_send", onLeadCaptureFormSendedHandler);
    }

    onLeadCaptureFormSended() {
        if (this.leadCapturedBox) {
            this.leadCaptureFormBox.classList.add('hidden');
            this.leadCapturedBox.classList.remove('hidden');
        } 
    }
}
