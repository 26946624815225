import { Controller } from 'stimulus';

export default class extends Controller {
   
   connect() {
      let bounds;
      this.rotateToMouseHandler = this.rotateToMouse.bind(this);
      var element = this.element;
      this.element.addEventListener('mouseenter', () => {
         bounds = element.getBoundingClientRect();
         document.addEventListener('mousemove', (e) => this.rotateToMouseHandler(e, bounds));
      });

      this.element.addEventListener('mouseleave', () => {
         document.removeEventListener('mousemove', (e) => this.rotateToMouseHandler(e, bounds));
         element.style.transform = '';
         element.style.background = '';
      });

   }

   rotateToMouse(e, bounds) {
      let element = e.target;
      const mouseX = e.clientX;
      const mouseY = e.clientY;
      const leftX = mouseX - bounds.x;
      const topY = mouseY - bounds.y;
      const center = {
         x: leftX - bounds.width / 2,
         y: topY - bounds.height / 2
      }
      const distance = Math.sqrt(center.x ** 2 + center.y ** 2);

      element.style.transform = `
         scale3d(1.07, 1.07, 1.07)
         rotate3d(
            ${center.y / 100},
            ${-center.x / 100},
            0,
            ${Math.log(distance) * 2}deg
         )
      `;

      element.querySelector('.glow').style.backgroundImage = `
         radial-gradient(
            circle at
            ${center.x * 2 + bounds.width / 2}px
            ${center.y * 2 + bounds.height / 2}px,
            #ffffff55,
            #0000000f
         )
      `;
   }
}
