import { Controller } from 'stimulus';
import PhotoSwipeLightbox from "photoswipe/src/js/lightbox/lightbox";
import PhotoSwipe from 'photoswipe/src/js/photoswipe';
// import 'photoswipe/src/photoswipe.css';
import Swiper from 'swiper/swiper-bundle';

export default class extends Controller {
    connect() {
        const options = {
            gallery: this.element,
            children: 'a',
            showHideAnimationType: 'zoom',
            loop: false,
            initialZoomLevel: 0.85,
            pswpModule: () => PhotoSwipe
        };
        const lightbox = new PhotoSwipeLightbox(options);
        lightbox.init();

        this.areaThumbs = this.element.querySelector(".area-thumbs");
        this.areaPhotos = this.element.querySelector(".area-photos");

        var galleryThumbs = new Swiper(this.areaThumbs, {
            spaceBetween: 20,
            slidesPerView: 3,
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
        });
        new Swiper(this.areaPhotos, {
            thumbs: {
                swiper: galleryThumbs
            },
        });
    }
}
