import { Controller } from 'stimulus';
import axios from 'axios';
import { EventEmitter } from '../utils/events';
import { toast } from '../utils';

export default class extends Controller {
    connect() {
        this.offset = Number(this.element.getAttribute('data-offset'));
        this.totalFotos = Number(this.element.getAttribute('data-total-fotos'));
        this.container = this.element.querySelector('.container-fotos');
        this.loadMoreBtn = this.element.querySelector('.load-more');
        this.loadMoreProgress = this.loadMoreBtn.querySelector('svg');
        this.loadMoreText = this.loadMoreBtn.querySelector('span');
        let onLoadMoreClickHandler = this.onLoadMoreClick.bind(this);
        this.loadMoreBtn.addEventListener('click', e => onLoadMoreClickHandler());
        this.caminho = this.element.getAttribute("data-url-load");
    }

    onLoadMoreClick() {
        this.loadMore();
    }

    async loadMore() {
        this.loadMoreBtn.disabled = true;
        this.loadMoreProgress.classList.remove('opacity-0');
        this.loadMoreText.classList.add('opacity-0');

        let formData = new FormData();
        formData.append('offset', this.offset);

        try {
            const response = await axios({
                method: "POST",
                url: this.caminho,
                headers: { 'Content-Type': 'multipart/form-data' },
                data: formData
            });
            const data = response.data;
            this.container.innerHTML += data.html;
            this.offset += Number(data.count);
            EventEmitter.dispatch('recalculate_scroll');
            if (this.offset >= this.totalFotos) {
                this.loadMoreBtn.classList.add('hidden');
            }
        } catch (error) {
            toast({
                type: 'error',
                title: 'Pedimos desculpas, Mas ocorreu um erro interno.'
            });
        }

        this.loadMoreProgress.classList.add('opacity-0');
        this.loadMoreText.classList.remove('opacity-0');
        this.loadMoreBtn.disabled = false;
    }
}
