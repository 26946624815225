import { Controller } from 'stimulus';
import { sizeOfThings } from '../utils';

export default class extends Controller {
    connect() {
        this.width = sizeOfThings()[0];
        this.base = this.element.querySelector('.base-scroll-events');
        this.baseMobile = this.element.querySelector('.base-mobile-scroll-events');
        this.boxBtns = this.element.querySelector('.box-share-artigo');
        this.figure = this.element.querySelector('figure');
        this.header = document.querySelector('header');
        this.circleSvgProgress = this.element.querySelector('.page-progress');
        this.fixed = false;
        this.boundingRectOriginal = this.boxBtns.getBoundingClientRect();
        this.boundingRectParentOriginal = this.base.getBoundingClientRect();
        this.boundingRectFigureOriginal = this.figure.getBoundingClientRect();
        this.originalWidth = this.boundingRectOriginal.width;

        this.calculateScrollStuffsHandler = this.calculateScrollStuffs.bind(this);

        window.addEventListener("scroll", this.calculateScrollStuffsHandler);
        window.addEventListener('resize', this.calculateScrollStuffsHandler, true);
        
        const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
        let scrollUpBtn = this.element.querySelector('.box-share-artigo button');
        if (scrollUpBtn) {
            scrollUpBtn.addEventListener('click', () => {
                if (supportsNativeSmoothScroll) {
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });
                } else {
                    window.scrollTo(0,0);
                }
            });
        }

    }

    calculateScrollStuffs(e) {
        var scrollPosY = document.querySelector('html').scrollTop;

        var totalArea = this.boundingRectParentOriginal.bottom;
        var relativeScroll = (scrollPosY - this.boundingRectParentOriginal.top);
        var perRelativeScroll = ((relativeScroll <= 0 ? 0 : relativeScroll) / totalArea);
        this.circleSvgProgress.style.strokeDashoffset = 157.08 - (157.08 * (perRelativeScroll > 1 ? 1 : perRelativeScroll));

        if (this.width > 768 && this.boundingRectOriginal.height < this.boundingRectParentOriginal.height) {
            var boundingRectHead = this.header.getBoundingClientRect();
            var boundingRect = this.boxBtns.getBoundingClientRect();
            var boundingRectParent = this.base.getBoundingClientRect();

            if (this.fixed && boundingRectParent.top > 0 /*boundingRectHead.height*/) {
                this.boxBtns.style.position = "absolute";
                this.boxBtns.style.bottom = "auto";
                this.boxBtns.style.top = "0";
                this.fixed = false;
                return;
            }
            if (this.fixed && boundingRectParent.bottom < this.boundingRectOriginal.height) {
                this.boxBtns.style.position = "absolute";
                this.boxBtns.style.bottom = "0";
                this.boxBtns.style.top = "auto";
                this.fixed = false;
                return;
            }
            if (!this.fixed && boundingRectParent.top < (boundingRectHead.height - 20) && boundingRectParent.bottom > this.boundingRectOriginal.height) {
                this.fixed = true;
                this.boxBtns.style.position = "fixed";
                this.boxBtns.style.top = boundingRectHead.height + "px";
                this.boxBtns.style.width = this.originalWidth + 20 + "px";
                return;
            }
        } else {
            this.fixed = false;
            this.boxBtns.style.position = "relative";
            this.boxBtns.style.top = "auto";
            this.boxBtns.style.width = "100%";
        }
    }
}
