import { Controller } from 'stimulus';
import Swiper from 'swiper';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';

export default class extends Controller {
    connect() {
        const progressCircle = this.element.querySelector(".autoplay-progress svg");
        const progressContent = this.element.querySelector(".autoplay-progress span");

        var slider = new Swiper(this.element, {
            modules: [EffectFade, Autoplay],
            spaceBetween: 0,
            centeredSlides: true,
            effect: "fade",
            fadeEffect: {
                crossFade: true,
            },
            autoplay: {
                delay: 8000,
                disableOnInteraction: false
            },
            on: {
                autoplayTimeLeft(s, time, progress) {
                    progressCircle.style.setProperty("--progress", 1 - progress);
                    progressContent.textContent = `${Math.ceil(time / 1000)}s`;
                }
            }
        });
    }
}
