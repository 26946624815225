import { Controller } from 'stimulus';

export default class extends Controller {
   connect() {
      this.wrapper = this.element.parentElement;
      this.select = this.wrapper.querySelector('select');
      this.select.style.pointerEvents = "none";
      this.onClickHandler = this.onClick.bind(this);
      this.onClickOutsideHandler = this.onClickOutside.bind(this);
      this.onKeyUpHandler = this.onKeyUp.bind(this);
      this.wrapper.addEventListener('click', this.onClickHandler);
      document.addEventListener('click', this.onClickOutsideHandler);
      document.addEventListener('keyup', this.onKeyUpHandler);
      this.searchInput = this.element.querySelector('input');
      this.onKeyUpSearchHandler = this.onKeyUpSearch.bind(this);
      this.searchInput.addEventListener('keyup', this.onKeyUpSearchHandler);
      this.items = this.element.querySelectorAll('.drop-item');
      this.onItemClickHandler = this.onItemClick.bind(this);
      this.items.forEach(item => item.addEventListener('click', this.onItemClickHandler));
   }
   onClick(e) {
      e.preventDefault();
      
      if (this.element.contains(e.target)) {
         return;
      }

      if (this.element.classList.contains('open')) {
         this.close();
      } else {
         this.element.classList.add('open');
      }
   }
   onClickOutside(e) {      
      if (this.wrapper.contains(e.target)) {
         return;
      }
      this.close();
   }
   onKeyUp(e) {
      if (e.keyCode == 27) {
         this.close();
      }
   }
   onKeyUpSearch(e = null) {
      let value = this.searchInput.value.trim();
      this.items.forEach(item => {
         if (item.innerHTML.toLowerCase().indexOf(value.toLowerCase()) !== -1 || value == "") {
            item.classList.remove('hidden');
            return;
         }
         item.classList.add('hidden');
      });
   }
   onItemClick(e) {
      let value = e.target.getAttribute('data-value');
      this.select.value = value;
      this.close();
   }
   close() {
      this.element.classList.remove('open');
      this.searchInput.value = "";
      this.onKeyUpSearch();
   }
}
