import { Controller } from 'stimulus';
import {EventEmitter} from '../events/event_emitter';
import ImageEditor from 'tui-image-editor';
import FileSaver from 'file-saver';
import blackTheme from 'tui-image-editor/src/js/ui/theme/theme.js';

export default class extends Controller {
    showClass = "show";
    locale_pt_BR = { // override default English locale to your custom
        'Crop': 'Recortar',
        'DeleteAll': 'Deletar todos',
        'Delete': 'Deletar',
        'Load': 'Carregar',
        'Download': 'Baixar',
        'Apply': 'Aplicar',
        'Draw': 'Desenhar',
        'Cancel': 'Cancelar',
        'Load Mask Image': 'Carregar máscara de imagem'
    };

    connect() {
        this.show = this.element.classList.contains(this.showClass);
        this.setShow();
        this.contentBox = this.element.querySelector('.modal-content');
        this.closeButton = this.element.querySelector(".close-modal");

        this.clickWinHandler = this.clickOutside.bind(this);
        this.closeButton.addEventListener('click', this.clickWinHandler);
        this.element.addEventListener('click', this.clickWinHandler);

        this.editImageEvent = this.editImage.bind(this);
        EventEmitter.subscribe("edit-image", this.editImageEvent);
    }
    initEditor(image){
        this.editor = new ImageEditor(this.contentBox.querySelector('#image-editor-box'), {
            includeUI: {
                loadImage: {
                    path: URL.createObjectURL(image),
                    name: image.name
                },
                locale: this.locale_pt_BR,
                theme: blackTheme,
                initMenu: 'filter',
                menuBarPosition: 'bottom'
            },
            usageStatistics: false,
            cssMaxWidth: 700,
            cssMaxHeight: 500,
            selectionStyle: {
                cornerSize: 20,
                rotatingPointOffset: 70
            }
        });
        let butttonsHeader = this.element.querySelector('.tui-image-editor-header-buttons');
        let buttonSalvar = document.createElement('button');
        buttonSalvar.innerHTML = 'Salvar'
        buttonSalvar.classList.add('btn-salvar-imagem');
        butttonsHeader.append(buttonSalvar);

        this.saveEditedImage = this.save.bind(this);
        buttonSalvar.addEventListener('click', this.saveEditedImage);
    }
    clickOutside(e) {
        if (this.show && (!this.contentBox.contains(e.target) || this.closeButton.contains(e.target))) {
            this.show = false;
            this.setShow();
        }
    }
    // toggle() {
    //     this.show = !this.show; 
    //     this.setShow();
    // }
    editImage({ image, onSave }){
        this.initEditor(image);
        this.show = true; 
        this.setShow();
        this.onSave = onSave;
    }
    save(){
        this.onSave(this.editor.toDataURL());
        this.show = false; 
        this.setShow();
    }
    setShow(){
        if (this.show) {
            this.element.style.display = 'block';
            this.element.classList.add(this.showClass);
            this.element.classList.add('fade');
        } else {
            this.element.classList.remove(this.showClass);
            let modal = this.element;
            modal.style.display = 'none';
        }
    }
}
