import { Controller } from 'stimulus';
import { action as FavAction, refresh } from '../services/favoritos';
import { toast } from '../utils';

export default class extends Controller {
    connect() {
        this.onClickHandler = this.onClick.bind(this);
        this.id = this.element.getAttribute('data-slug');
        this.element.addEventListener('click', this.onClickHandler);
    }
    onClick(e) {
        e.preventDefault();
        if (this.element.classList.contains('favorito')) {
            this.removeFavorito(this.id);
        } else {
            this.addFavorito(this.id);
        }
    }
    showLoading() {
        this.element.classList.add('loading');
    }
    hideLoading() {
        this.element.classList.remove('loading');
    }

    addFavorito(id){  
        this.showLoading();
        let hideLoadingHandler = this.hideLoading.bind(this);
        FavAction(id, 'add', response => {
            hideLoadingHandler();
            document.querySelectorAll('.btn-fav')?.forEach(btn => {
                let idCurrent = btn.getAttribute('data-slug');
                if (id == idCurrent) {
                    if (!btn.classList.contains('favorito')) btn.classList.add('favorito'); 
                    btn.innerHTML = '<i class="fas fa-heart"></i>';
                }
            });

            toast({ type: 'success', title: 'Imóvel adicionado dos favoritos!' });

            document.querySelectorAll('.number-favs')?.forEach(e => {
                e.innerHTML = response.favoritos;
            });

            FavAction(0, "get_dados", response => {
                refresh(response);
            });
        });
    }
    removeFavorito(id){
        this.showLoading();
        let hideLoadingHandler = this.hideLoading.bind(this);
        FavAction(id, 'remove', response => {
            hideLoadingHandler();
            document.querySelectorAll('.btn-fav')?.forEach(btn => {
                let idCurrent = btn.getAttribute('data-slug');
                if (id == idCurrent) {
                    if (btn.classList.contains('favorito')) btn.classList.remove('favorito'); 
                    btn.innerHTML = '<i class="far fa-heart"></i>';
                }
            });

            toast({ type: 'success', title: 'Imóvel removido dos favoritos!' });

            document.querySelectorAll('.number-favs')?.forEach(e => {
                e.innerHTML = response.favoritos;
            });

            FavAction(0, "get_dados", response => {
                refresh(response);
            });
        });
    }
}
