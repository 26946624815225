import { Controller } from 'stimulus';
import { sizeOfThings } from '../utils';

export default class extends Controller {
    connect() {
        this.sections = this.element.querySelectorAll(".section");
        this.onScrollHandler = this.onScroll.bind(this);

        document.addEventListener('scroll', this.onScrollHandler);

        if (URL_POPUP) {
            this.scrollTo(URL_POPUP);
        }
    }
    scrollTo(_url) {
        let target = null;
        
        this.sections?.forEach((section, k) => {
            let url = section.getAttribute("data-url");
            if (url == _url) {
                target = section;
                return;
            }
        });

        if (target) {
            var doc = document.documentElement;
            var top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
            //
            const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
            let boundingRect = target.getBoundingClientRect();
            let heightMenu = 0;
            if (sizeOfThings()[0] <= 992) {
                sideMenu.classList.remove('sidemenu-open');
                heightMenu = document.querySelector('div[data-controller="navbar-effect"]').getBoundingClientRect().height;
            } else {
                heightMenu = document.querySelector('div[data-controller="navbar-effect"]').getBoundingClientRect().height;
            }
            
            if (supportsNativeSmoothScroll) {
                window.scrollTo({
                    top: (boundingRect.top + top) - heightMenu,
                    left: 0,
                    behavior: 'smooth'
                });
            } else {
                window.scrollTo(0,(boundingRect.top + top) - heightMenu);
            }
        }
    }
    onScroll(e) {
        let scrollPosY = window.scrollY;
        let intersect = false;

        this.sections?.forEach((section, k) => {
            let rect = section.getBoundingClientRect();
            let url = section.getAttribute("data-url");

            if (url && rect.top <= window.innerHeight/2 && rect.bottom >= rect.height / 2) {
                let title = section.getAttribute('data-title');
                this.changeUrl(url, title);
                this.updateLinks(section.id);
                intersect = true;
                return;
            }
        });

        if (intersect) return;

        this.cleanUrl();
    }
    updateLinks(idtarget) {
        // document.querySelectorAll('header nav a.text-secondary')?.forEach(link => {
        //     link.classList.remove('text-secondary');
        //     link.classList.add('text-secondary/50');
        // });
        // let link = document.querySelector('header nav a[data-target="#'+idtarget+'"]');
        // link.classList.remove('text-secondary/50');
        // link.classList.add('text-secondary');
    }
    cleanUrl() {
        document.title = TITLE;
        window.history.replaceState({url: "" + CURRENT_PATH + ""}, TITLE, CURRENT_PATH);
    }
    changeUrl(url, title = "") {
        var targetTitle = title != "" ? title + ' | ' + COMPANY_NAME : COMPANY_NAME;
        if (document.href != url) {
            document.title = targetTitle;
            window.history.pushState({url: "" + url + "", title: targetTitle}, targetTitle, url);
        }
    }
}
