import { Controller } from 'stimulus';
import { sizeOfThings } from '../utils';

export default class extends Controller {
   connect() {
      let width = sizeOfThings()[0];
      let boundingRectOriginal = this.element.getBoundingClientRect();
      let boundingRectParentOriginal = this.element.parentElement.getBoundingClientRect();
      let originalWidth = boundingRectOriginal.width;
      this.fixed = false;

      window.addEventListener("scroll", e => {
         if (width > 780 && boundingRectOriginal.height < boundingRectParentOriginal.height) {
            let scrollPosY = window.scrollY;
            let header = document.querySelector('.ctn-header');
            let boundingRectHead = header.getBoundingClientRect();
            let boundingRect = this.element.getBoundingClientRect();
            let boundingRectParent = this.element.parentElement.getBoundingClientRect();
            console.log(boundingRectParent.bottom, boundingRectOriginal.height);

            if (this.fixed && boundingRectParent.top > boundingRectHead.height) {
               this.element.style.position = "absolute";
               this.element.style.bottom = "auto";
               this.element.style.top = "0";
               this.fixed = false;
               return;
            }
            if (this.fixed && boundingRectParent.bottom < boundingRectOriginal.height) {
               this.element.style.position = "absolute";
               this.element.style.bottom = "0";
               this.element.style.top = "auto";
               this.fixed = false;
               return;
            }
            if (!this.fixed && boundingRectParent.top < boundingRectHead.height && boundingRectParent.bottom > boundingRectOriginal.height) {
               this.fixed = true;
               this.element.style.position = "fixed";
               this.element.style.top = boundingRectHead.height + 20 + "px";
               this.element.style.width = originalWidth + "px";
               return;
            }
         } else {
            this.fixed = false;
            this.element.style.position = "relative";
            this.element.style.top = "auto";
            this.element.style.width = "100%";
         }
      });
   }
}
