import { Controller } from 'stimulus';
import { EventEmitter } from '../events/event_emitter';

export default class extends Controller {
   connect() {
      this.button = this.element;

      this.inputs = document.querySelectorAll(".table-permissoes .active-checkbox input");
      var totalInputs = this.inputs.length;
      var inputsChecked = 0;
      var inputsUnchecked = 0;

      for (var i = 0; i < this.inputs.length; i++) {
         var input = this.inputs[i];
         if (input.checked) {
            inputsChecked++;
         } else {
            inputsUnchecked++;
         }
      }

      this.button.setAttribute("data-checked", inputsChecked >= inputsUnchecked);
      this.button.innerHTML = inputsChecked >= inputsUnchecked ? "Desativar todos" : "Ativar todos";

      //
      this.initClick();
      this.initClickHandler = this.initClick.bind(this);
      this.onClickHandler = this.onButtonClick.bind(this);

      EventEmitter.subscribe("datatable_change", ({ table }) => {
         if (table.contains("table-permissoes")) {
            console.log(table);
            this.initClickHandler();
         }
      });
   }

   initClick() {
      this.button.removeEventListener("click", this.onClickHandler)
      this.button.addEventListener("click", this.onClickHandler);
   }

   onButtonClick(e) {
      console.log('teste');
      let button = this.button;
      console.log(this.inputs);
      var checked = button.getAttribute("data-checked") == "true";
      for (var i = 0; i < this.inputs.length; i++) {
         var input = this.inputs[i];
         input.checked = !checked;
      }
      button.setAttribute("data-checked", !checked);
      button.innerHTML = !checked ? "Desativar todos" : "Ativar todos";
   }
}
