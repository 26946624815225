import { Controller } from 'stimulus';
import PhotoSwipeLightbox from "photoswipe/src/js/lightbox/lightbox";
import PhotoSwipe from 'photoswipe/src/js/photoswipe';
// import 'photoswipe/src/photoswipe.css';
import Swiper from 'swiper';

export default class extends Controller {
   connect() {
      var gallery = new Swiper(this.element, {
         spaceBetween: 10,
         slidesPerView: 2,
         watchSlidesVisibility: true,
         watchSlidesProgress: true,
         pagination: {
            el: this.element.querySelector('.swiper-pagination'),
            type: 'bullets',
         },
         breakpoints: {
            0: {
               slidesPerView: 1,
            },
            490: {
              slidesPerView: 2,
            },
         }
      });
   }
}
