import axios from 'axios';
import { Controller } from 'stimulus';
import _, { result } from 'lodash';

export default class extends Controller {
    connect() {
        this.resultsContainer = this.element.querySelector('.results-container');
        this.input = this.element.querySelector('input');
        this.loadHandler = this.load.bind(this);
        this.btnsSearch = document.querySelectorAll('.btn-search');
        var debounceLoad = _.debounce(this.loadHandler, 800);
        // this.input.addEventListener('keyup', (e) => debounceLoad(e.target));

        this.openHandler = this.open.bind(this);
        this.btnsSearch.forEach(btn => btn.addEventListener('click', this.openHandler));

        this.closeHandler = this.close.bind(this);
        this.element.querySelector('.fixed').addEventListener('click', this.closeHandler);

        this.keyDownHandler = this.keyDown.bind(this);
        document.addEventListener('keydown', this.keyDownHandler);

        this.locale = this.element.getAttribute('data-locale');
    }

    keyDown(e) {
        if (e.ctrlKey && e.key === 'k' || e.key === 'K') {
            e.preventDefault();
            this.open();
        }
        if (e.key === 'Escape') {
            this.close();
        }
    }

    open() {
        this.element.classList.remove('hidden');
        this.element.querySelector('.fixed').classList.add('opacity-100');
        this.input.focus();
    }

    close() {
        this.resultsContainer.innerHTML = "";
        this.input.value = "";
        this.element.classList.add('hidden');
        this.element.querySelector('.fixed').classList.remove('opacity-100');
    }

    async load(input) {
        // let term = input.value;
        // if (term == "" || term == null) return;
        // this.resultsContainer.innerHTML = "Carregando..";
        // const { data } = await axios.get(`/${this.locale}/search/${term}`);
        // this.updateUi(data);
    }

    updateUi(results) {
        this.resultsContainer.innerHTML = "";

        for (var i = 0; i < results.length; i++) {
            var result = results[i];

            var a = document.createElement('a');
            a.classList = 'w-full group px-4 py-4 bg-black/[.07] rounded-2xl flex flex-col hover:bg-white/[.8] transition';
            a.href = result.link;

            var title = document.createElement('b');
            title.classList = 'text-white text-sm font-primary-bold group-hover:text-primary';
            title.innerHTML = result.title;

            var text = document.createElement('div');
            text.classList = 'text-white mt-[.42rem] text-[.72rem] leading-tight group-hover:text-black';
            text.innerHTML = result.text;

            a.appendChild(title);
            a.appendChild(text);

            this.resultsContainer.appendChild(a);
            
            if (i < results.length - 1) {
                var hr = document.createElement('hr');
                hr.classList = 'bg-white/[.15] my-3 border-none h-[2px] w-[calc(100%-2rem)] mx-auto';
                this.resultsContainer.appendChild(hr);
            }
        }
    }
}
