import { Controller } from 'stimulus';
import loadingPanel from '../../public/img/_layout/loading_panel.svg';

export default class extends Controller {
    showClass = "show";
    
    connect() {
        this.show = this.element.classList.contains(this.showClass);
        
        this.modal = document.querySelector(this.element.getAttribute('data-target'));
        this.url = this.modal.getAttribute('data-url') ?? null;
        this.title = this.modal.getAttribute('data-title') ?? null
        if (this.url) {
            if (this.url == URL_POPUP) {
                this.toggle();
            }
        }
        this.contentBox = this.modal.querySelector(".modal-content");
        this.closeButton = this.modal.querySelectorAll(".close-modal");

        this.mainContent = document.querySelector('.main-content');
        
        this.clickWinHandler = this.clickOutside.bind(this);
        this.clickOpenButton = this.toggle.bind(this);
        this.element.addEventListener('click', this.clickOpenButton);
        
        this.closeButton.forEach(item => {
            item.addEventListener('click', this.clickWinHandler);
        });
        this.modal.addEventListener('click', this.clickWinHandler);
    }
    changeUrl() {
        if (this.url) { 
            if (this.show) {
                var targetTitle = this.title + ' | ' + COMPANY_NAME;
                document.title = targetTitle;
                window.history.pushState({url: "" + this.url + "", title: targetTitle}, targetTitle, this.url);
            } else {
                document.title = PAGE_TITLE;
                window.history.replaceState({url: "" + CURRENT_PATH + ""}, PAGE_TITLE, CURRENT_PATH);
            }
        }
    }
    loadContent() {
        // this.contentBox.innerHTML = loadingPanel;
    }
    clickOutside(e) {
        e.preventDefault();
        let closeButtonContains = false;
        this.closeButton.forEach(btn => { if (btn.contains(e.target)) closeButtonContains = true; });
        if (this.show && (!this.contentBox.contains(e.target) || closeButtonContains)) {
            this.show = false;
            this.setShow();
        }
    }
    toggle() {
        if (this.element.classList.contains('dropdown-item')) {
            // close sidemenu
            let sideMenu = document.querySelector('.ctn-side-menu');
            sideMenu?.classList.remove('sidemenu-open');
        }
        this.show = true; 
        this.setShow();
        this.loadContent();
    }
    setShow(){
        let modal = this.modal;
        this.changeUrl();
        if (this.show) {
            if (this.mainContent) this.mainContent.classList.add('onTopLayer');
            document.querySelector('body').style.overflowY = 'hidden';
            this.modal.style.display = 'block';
            setTimeout(() => {
                modal.classList.add(this.showClass);
            }, 100);
        } else {
            if (this.mainContent) this.mainContent.classList.remove('onTopLayer');
            this.modal.transition = 'none';
            this.modal.webkitTransition = 'none';
            this.modal.classList.remove(this.showClass);
            const onTransitionEnd =  function(e) {
                if (modal == e.target) {
                    modal.style.display = 'none';
                    document.querySelector('body').style.overflowY = 'scroll';
                    modal.removeEventListener('transitionend', onTransitionEnd);
                }
            };
            this.modal.addEventListener('transitionend', onTransitionEnd);
        }
    }
}