import Pikaday from 'pikaday';
import { bytesToSize, stringToSlug, imageUploadHandler, b64toBlob, sizeOfThings } from "./utils";
import VMasker from 'vanilla-masker';
import tinymce from 'tinymce';
import { EventEmitter } from './events/event_emitter';
import Dropzone from 'dropzone';
import PerfectScrollbar from 'perfect-scrollbar';

export default function init(editor = true, masks = true) {

    if (editor === true) {
        // ----------------------------- EDITOR -----------------------------------
        var useDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
        tinymce.baseURL = '/editor';
        tinymce.init({
            selector: 'textarea.editor',
            plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
            imagetools_cors_hosts: ['picsum.photos'],
            menubar: 'file edit view insert format tools table help',
            toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
            toolbar_sticky: true,
            autosave_ask_before_unload: true,
            autosave_interval: '30s',
            autosave_prefix: '{path}{query}-{id}-',
            autosave_restore_when_empty: false,
            autosave_retention: '2m',
            image_advtab: true,
            importcss_append: true,
            file_picker_callback: function (callback, value, meta) {
                /* Provide file and text for the link dialog */
                if (meta.filetype === 'file') {
                    callback('https://www.google.com/logos/google.jpg', { text: 'My text' });
                }

                /* Provide image and alt text for the image dialog */
                if (meta.filetype === 'image') {
                    callback('https://www.google.com/logos/google.jpg', { alt: 'My alt text' });
                }

                /* Provide alternative source and posted for the media dialog */
                if (meta.filetype === 'media') {
                    callback('movie.mp4', { source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg' });
                }
            },
            template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
            template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
            height: 600,
            image_caption: true,
            quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
            noneditable_noneditable_class: 'mceNonEditable',
            toolbar_mode: 'sliding',
            contextmenu: 'link image imagetools table',
            // skin: useDarkMode ? 'oxide-dark' : 'oxide',
            // content_css: useDarkMode ? 'dark' : 'default',
            skin: 'oxide',
            content_css: 'default',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            language: 'pt_BR',
            images_upload_handler: imageUploadHandler,
            setup: editor => {
                editor.on('change', () => {
                    const content = editor.getContent();
                    editor.targetElm.value = content;
                });
            },
        });
    }

    // ------------------------- PERFECT SCROLL ---------------------------
    var pss = document.querySelectorAll('.per-scroll');
    var psObjs = [];
    pss.forEach((ps) => {
        let notSrollIn = ps.getAttribute('not-scroll-in');
        if (notSrollIn == null || Number(notSrollIn) < sizeOfThings()[0]) {
            let psObj = new PerfectScrollbar(ps, {
                wheelSpeed: 2,
                wheelPropagation: true,
                minScrollbarLength: 20,
                suppressScrollX: true
            });
            psObjs.push(psObj);
        }
    });
    window.addEventListener('resize', function () {
        psObjs.forEach((psObj) => {
            let notSrollIn = psObj.element?.getAttribute('not-scroll-in');
            if (notSrollIn != null && Number(notSrollIn) >= sizeOfThings()[0]) {
                psObj.destroy();
                psObj = null;
            }
        });
    });

    // --------------------------------- DROPZONE ------------------------------------
    var dropzonesElems = document.querySelectorAll('[data-toggle="dropzone"]');
    dropzonesElems.forEach((elem) => {
        var previewTemplate = elem.querySelector('.dz-preview');
        var t = void 0 !== elem.getAttribute("data-dropzone-multiple");
        var i = void 0;
        var options = {
            url: elem.getAttribute("data-dropzone-url"),
            thumbnailWidth: null,
            thumbnailHeight: null,
            previewsContainer: previewTemplate,
            previewTemplate: previewTemplate.innerHTML,
            maxFiles: t ? null : 1,
            resizeWidth: 1800,
            acceptedFiles: t ? null : "image/*",
            init: function () {
                this.on("addedfile", function (e) {
                    !t && i && this.removeFile(i), i = e;
                });
                this.on("uploadprogress", function (e, p) {
                    console.log(e);
                    e.previewTemplate.querySelector('.dz-progress').innerHTML = p == 100 ? 'Concluído' : Math.round(p) + '%';
                });
            }
        };
        previewTemplate.innerHTML = "";
        var dropzones = new Dropzone(elem, options);
    });

    // ----------------------------- UTILS FUNCS -----------------------------------
    String.prototype.isEmpty = function () {
        return (this.length === 0 || !this.trim());
    };
    String.prototype.replaceAll = String.prototype.replaceAll || function (needle, replacement) {
        return this.split(needle).join(replacement);
    };

    // ---------------------------- DATEPICKER -------------------------------------
    // add datepicker for class datepicker
    var datepickers = document.querySelectorAll(".datepicker");
    datepickers.forEach(function (elem) {
        var picker = new Pikaday({
            field: elem,
            format: 'D/M/YYYY',
            toString(date, format) {
                // you should do formatting based on the passed format,
                // but we will just return 'D/M/YYYY' for simplicity
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const year = date.getFullYear();
                return `${day}/${month}/${year}`;
            },
            parse(dateString, format) {
                // dateString is the result of `toString` method
                const parts = dateString.split('/');
                const day = parseInt(parts[0], 10);
                const month = parseInt(parts[1], 10) - 1;
                const year = parseInt(parts[2], 10);
                return new Date(year, month, day);
            }
        });
    });

    // ----------------------- focused effect in admin inputs ----------------------
    let labels = document.querySelectorAll('.input-group-painel.retractable');
    labels.forEach(function (elem) {
        elem.addEventListener('click', function () {
            this.parentNode.classList.add('focused');
            this.querySelector('input').focus();
        });
    });
    let inputs = document.querySelectorAll('.input-group-painel input.form-control');
    inputs.forEach(function (elem) {
        if (!elem.value.isEmpty()) {
            elem.parentNode.parentNode.classList.add('focused');
        }
        elem.addEventListener('focusin', function () {
            elem.parentNode.parentNode.classList.add('focused');
        });
        elem.addEventListener('focusout', function () {
            if (elem.value.isEmpty()) {
                elem.parentNode.parentNode.classList.remove('focused');
            }
        });
    });

    // ------------------------- input file admin ----------------------------------
    let fileInputs = document.querySelectorAll('form input[type=file]');
    fileInputs.forEach(function (elem) {
        if (elem.getAttribute("name").indexOf('[]') === -1) {
            elem.addEventListener('change', function (event) {
                let file = event.target.files[0];
                let tmppath = URL.createObjectURL(file);
                let parentNode = this.parentNode;
                let curInput = this;
                parentNode.querySelector(".custom-file-label span").classList.add('fade-out');
                parentNode.querySelector(".wrapper-file-label").style.display = 'inline-block';
                parentNode.querySelector(".wrapper-file-label img").setAttribute('src', tmppath);
                parentNode.querySelector(".wrapper-file-label p").innerHTML = file.name;
                parentNode.querySelector(".wrapper-file-label small").innerHTML = bytesToSize(file.size);
                parentNode.querySelector(".edit-file").style.display = "block";
                parentNode.querySelector(".edit-file").addEventListener('click', function () {
                    let curInput = this.parentNode.parentNode.querySelector('.custom-file-input');
                    if (curInput.getAttribute('data-src')) {
                        let base64StringType = /,(.+)/.exec(curInput.getAttribute('data-src'));
                        let blob = b64toBlob(base64StringType[1], base64StringType[0]);
                        file = new File([blob], curInput.files[0].name + "_edited.png");
                    }
                    EventEmitter.dispatch("edit-image", {
                        image: file,
                        onSave: (imageEdited) => {
                            parentNode.querySelector(".wrapper-file-label img").setAttribute('src', imageEdited);
                            curInput.setAttribute('data-src', imageEdited);
                        }
                    });
                });
                parentNode.querySelector(".remove-file").style.display = "block";
                parentNode.querySelector(".remove-file").addEventListener('click', function () {
                    parentNode.querySelector(".custom-file-label span").classList.remove('fade-out');
                    parentNode.querySelector(".wrapper-file-label").style.display = 'none';
                    curInput.value = '';
                    curInput.setAttribute('data-src', '');
                });
            });
        }
    });

    // ----------------------- disable checkboxes in admin inputs ----------------------
    let checkboxesDisables = document.querySelectorAll(".checkbox-disable-input");
    if (checkboxesDisables != null && checkboxesDisables.length > 0) {
        checkboxesDisables.forEach((cb, k) => {
            let checkbox = cb.querySelector("input");
            let inputNameTarget = checkbox.getAttribute("data-name");
            let inputTarget = document.querySelector(".disableable-box *[name=" + inputNameTarget + "]");
            console.log(inputTarget);
            let boxTarget = document.querySelector(".disableable-box[data-name=" + inputNameTarget + "]");
            if (inputTarget != null) {
                if (checkbox.checked) {
                    inputTarget.disabled = false;
                    inputTarget.classList.remove("ignore-form");
                    boxTarget.classList.remove("disabled");
                } else {
                    inputTarget.disabled = true;
                    inputTarget.classList.add("ignore-form");
                    boxTarget.classList.add("disabled");
                }
            }

            checkbox.addEventListener("change", (e) => {
                if (inputTarget != null) {
                    if (checkbox.checked) {
                        inputTarget.disabled = false;
                        inputTarget.classList.remove("ignore-form");
                        boxTarget.classList.remove("disabled");
                    } else {
                        inputTarget.disabled = true;
                        inputTarget.classList.add("ignore-form");
                        boxTarget.classList.add("disabled");
                    }
                }
            });
        });
    }


    // ------------------------ select with parents -------------------------------- 
    let hasParentInputs = document.querySelectorAll('.hasParent');
    hasParentInputs.forEach(function (elem) {
        elem.addEventListener('change', e => {
            let option = elem.querySelector('option:checked');
            let parentName = elem.getAttribute('name');
            let val = option.value;

            document.querySelectorAll('select[data-parent=' + parentName + '] option').forEach(function (elem) {
                if (elem.value == 0) {
                    elem.selected = true;
                }
                if (val == 0) {
                    elem.style.display = 'block';
                } else {
                    if (elem.getAttribute('data-parent-id') == val) {
                        elem.style.display = 'block';
                    } else {
                        elem.style.display = 'none';
                    }
                }
            });
        });
    });

    // -------------------------------- HISTORY ------------------------------------ 
    window.onpopstate = function (e) {
        var u = e.state.url;
        var ref = e.state.ref;
        document.title = e.state.title;
        if (ref) {
            document.querySelector(`data-target="${ref}"`)?.click();
        }
    };

    // -------------------------------- TABS ------------------------------------ 
    let tabs = document.querySelectorAll('[data-toggle="tab"]');
    tabs?.forEach(tab => {
        let target = tab.getAttribute('href');
        let tabsGroup = tab.getAttribute('data-tabsgroup');
        tab.addEventListener('click', e => {
            e.preventDefault();
            document.querySelectorAll(`[data-tabsgroup="${tabsGroup}"]`)?.forEach(tab => {
                tab.classList.remove('active');
            });
            tab.classList.add('active');
            document.querySelectorAll(tabsGroup + ' .tab-pane')?.forEach(target => {
                target.classList.remove('fade');
                target.classList.remove('show');
                target.classList.remove('active');
            });
            let targetElem = document.querySelector(target);
            targetElem?.classList.add('active');
            targetElem?.classList.add('show');
            targetElem?.classList.add('fade');
        });
    });

    // -------------------------------- slugify ------------------------------------ 
    let slugifies = document.querySelectorAll('.slugify');
    slugifies.forEach(function (elem) {
        let slugField = elem;
        let fieldname = elem.getAttribute('data-slugify-field');
        document.querySelector('input[name=' + fieldname + ']').addEventListener('keyup', function (e) {
            let slug = stringToSlug(this.value);
            if (slug) {
                slugField.parentNode.parentNode.classList.add('focused');
            } else {
                slugField.parentNode.parentNode.classList.remove('focused');
            }
            slugField.value = slug;
        });
        elem.addEventListener('keyup', function (e) {
            let slug = stringToSlug(this.value);
            if (slug) {
                this.parentNode.parentNode.classList.add('focused');
            } else {
                this.parentNode.parentNode.classList.remove('focused');
            }
        });
    });

    if (masks === true) {
        // -------------------------- maskeds classes ---------------------------------- 
        VMasker(document.querySelectorAll(".money-input")).maskMoney({
            precision: 2,
            separator: ',',
            delimiter: '.',
            unit: 'R$',
            suffixUnit: '',
            zeroCents: false
        });
        VMasker(document.querySelectorAll(".cep-input")).maskPattern("99999-999");
    }
}