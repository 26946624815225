import { Controller } from 'stimulus';

export default class extends Controller {
    connect() {
        this.dismissible = this.element.classList.contains('alert-dismissible');
    }
    close() {
        this.element.remove();
    }
}
