import { Controller } from 'stimulus';

export default class extends Controller {
    connect() {
        this.openHandler = this.open.bind(this);
        this.closeHandler = this.close.bind(this);
        this.caracteristicasData = JSON.parse(this.element.getAttribute('data-caracteristicas'));

        if (imoveis_filtros !== null) {
            console.log(imoveis_filtros);

            let goFilters = this.goUrlFiltros.bind(this);

            let buttonGoFilters = document.querySelectorAll('.btn-go-url-filters')?.forEach(button => {
                button.addEventListener('click', e => {
                    goFilters();
                });
            });

            let buttonsRemoveFilter = document.querySelectorAll('.btn-remove-filtro')?.forEach(button => {
                button.addEventListener('click', e => {
                    let tipo = button.getAttribute('data-type');
                    let item = button.getAttribute('data-item');
                    if (item) {
                        let items = imoveis_filtros[tipo].split(',');
                        items = items.filter(_item => _item != item);
                        imoveis_filtros[tipo] = items.join(',');
                    } else {
                        delete imoveis_filtros[tipo];
                    }
                    goFilters();
                });
            });

            document.querySelectorAll('.btn-filtros-full')?.forEach(btn => {
                btn.addEventListener('click', e => {
                    this.openHandler();
                });
            });
            document.querySelectorAll('.btn-filtros-full-close')?.forEach(btn => {
                btn.addEventListener('click', e => {
                    this.closeHandler();
                });
            });
            document.addEventListener("keyup", e => {
                if (e.which == 27 || e.code == "Escape") {
                    this.closeHandler();
                }
            });

            this.initHandleInputs();
            this.initCharStuffs();
        }
    }

    open() {
        document.querySelector('#filtros-full')?.classList.add('open');
        document.querySelector('.overlay-filtros-full')?.classList.add("open");
        document.querySelector('body').style.overflowY = "hidden";
    }

    close() {
        document.querySelector('#filtros-full')?.classList.remove('open');
        document.querySelector('.overlay-filtros-full')?.classList.remove("open");
        document.querySelector('body').style.overflowY = "scroll";
    }

    initHandleInputs() {
        let tabsTransacoesEls = [
            ...document.querySelectorAll(".filtros-bar .tab-transacao"), 
            ...this.element.querySelectorAll(".tab-transacao")
        ];
        let tabsTransacao = tabsTransacoesEls?.forEach(tabTransacao => {
            tabTransacao.addEventListener('click', e => {
                let slug = e.target.getAttribute("data-slug");
                imoveis_filtros.transacao = slug;
            });
            if (tabTransacao.classList.contains('active')) {
                tabTransacao.click();
                tabTransacao.dispatchEvent(new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window
                }));
            }
        });
        //
        let selectsHandlesEls = [
            ...document.querySelectorAll(".filtros-bar select:not(.not-handle-this)"), 
            ...this.element.querySelectorAll("select:not(.not-handle-this)")
        ];
        let selectsHandles = selectsHandlesEls?.forEach(selectHandle => {
            selectHandle.addEventListener('change', e => {
                let val = selectHandle.querySelector('option:checked')?.value;
                let nome = selectHandle.getAttribute('name');
                if (imoveis_filtros[nome] !== val) {
                    imoveis_filtros[nome] = val;
                }
            });
        });
        //
        let multipleSelectsHandlesEls = [
            ...document.querySelectorAll(".filtros-bar .select-multiple:not(.not-handle-this) .sm-item"), 
            ...this.element.querySelectorAll(".select-multiple:not(.not-handle-this) .sm-item")
        ];
        multipleSelectsHandlesEls?.forEach(item => {
            item.addEventListener('click', e => {
                setTimeout(() => {
                    let multipleSelect = item.parentElement.parentElement;
                    let val = multipleSelect.getAttribute('data-value');
                    let nome = multipleSelect.getAttribute('data-name');
                    if (imoveis_filtros[nome] !== val) {
                        imoveis_filtros[nome] = val;
                    }
                }, 200);
            });
        });
        //
        let inputsHandlesEls = [
            ...document.querySelectorAll(".filtros-bar input:not(.not-handle-this)"), 
            ...this.element.querySelectorAll("input:not(.not-handle-this)")
        ];
        let inputsHandles = inputsHandlesEls?.forEach(inputHandle => {
            inputHandle.addEventListener('change', e => {
                let nome = inputHandle.getAttribute('name');
                if (inputHandle.getAttribute('type') == 'checkbox' && nome == 'caracteristicas') {
                    var id = inputHandle.getAttribute('data-id');
                    var caracteristicas = imoveis_filtros.caracteristicas ? imoveis_filtros.caracteristicas.split('~') : [];
                    var index = caracteristicas.indexOf(id);
                    if (index === -1) {
                        caracteristicas.push(id);
                    } else {
                        caracteristicas.splice(index, 1);
                    }
                    imoveis_filtros.caracteristicas = caracteristicas.join('~');
                } else {
                    let val = inputHandle.value;
                    if (imoveis_filtros[nome] !== val) {
                        if (nome.indexOf('valor_') >= 0 || nome.indexOf('area_') >= 0) 
                            val = val.replace('R$', '').replaceAll('.', '').replaceAll(',', '.').replaceAll('!', '').trim();
                        imoveis_filtros[nome] = val;
                    }
                }
            });
        });
        //
        let btnsEls = [
            ...document.querySelectorAll(".filtros-bar .manipulate-filters"), 
            ...this.element.querySelectorAll(".manipulate-filters")
        ];
        let btns = btnsEls?.forEach(btn => {
            btn.addEventListener('click', e => {
                let val = btn.getAttribute('data-value');
                let prop = btn.getAttribute('data-prop');
                if (imoveis_filtros[prop] !== val) {
                    imoveis_filtros[prop] = val;
                }
                //
                this.element.querySelectorAll('.manipulate-filters[data-prop='+prop+']')?.forEach(btn => {
                    btn.classList.remove('active');
                });
                btn.classList.add('active');
            });
        });
    }

    initCharStuffs() {
        // toggle all
        let buttonShowAllChar = this.element.querySelector('.btn-ver-todas-caracs');
        let onClickShowAllChar = this.showAllChar.bind(this);
        buttonShowAllChar?.addEventListener('click', onClickShowAllChar);

        // filter caracs por tipo
        this.onSelectTipoChangeHandler = this.onSelectTipoChange.bind(this);
        let selectTipo = this.element.querySelector("select[name=tipo]:not(.not-handle-this)");
        let selectMultipleTipoItems = this.element.querySelectorAll(".select-multiple[data-name=tipos]:not(.not-handle-this) .sm-item");
        if (selectMultipleTipoItems) {
            selectMultipleTipoItems?.forEach(item => item.addEventListener('click', this.onSelectTipoChangeHandler));
        }
        if (selectTipo) {
            selectTipo.addEventListener('change', this.onSelectTipoChangeHandler);
        }
        this.updateVibilityCaracs();

        // searching...
        let searchInput = this.element.querySelector('input[name=buscar-caracteristica]');
        let onChangeSearch = this.searchChar.bind(this);
        searchInput?.addEventListener('keyup',onChangeSearch);

        //clear search
        let buttonCleanSearch = this.element.querySelector('.limpar-busca');
        buttonCleanSearch?.addEventListener('click', e => {
            searchInput.value = "";
            let list = this.element.querySelector('.list-caracs');
            list?.classList.remove('open');
            buttonCleanSearch.parentNode.classList.remove('with-content');
            this.updateVibilityCaracs(searchInput.value);
        });

    }

    onSelectTipoChange(e) {
        // let curTipo = e.target.options[e.target.selectedIndex];
        // let caracs = this.element.querySelectorAll('input[name=caracteristicas]');
        // console.log(curTipo.getAttribute('data-id'));
        // caracs?.forEach(carac => {
        //     if (curTipo.getAttribute('data-id') != 0 && curTipo.getAttribute('data-id') != this.getTipoCarac(carac.value)) {
        //         carac.parentNode.classList.add('force-hidde');
        //     }
        // });
        setTimeout(() => {
            this.updateVibilityCaracs();
        }, 200);
    }

    getTipoCarac(caracId) {
        return this.caracteristicasData.reduce(
           (acc, value) => (value.id == caracId) ? value.tipoSlug : acc,
           null
        );
    }

    updateVibilityCaracs(searchTerm = "") {
        let tipoEl = this.element.querySelector(".select-multiple[data-name=tipos]:not(.not-handle-this)");
        let curTipoSlugs = tipoEl && tipoEl.getAttribute('data-value') ? tipoEl.getAttribute('data-value').split(',') ?? [] : [];
        let caracs = this.element.querySelectorAll('input[name=caracteristicas]');
        caracs?.forEach(carac => {
            if (!carac.parentNode.classList.contains('hidde')) carac.parentNode.classList.add('hidde');
            let nome = carac.getAttribute('data-nome');
            let matchWithSearch = searchTerm == "" || nome.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
            
            if (!matchWithSearch) {
                carac.parentNode.classList.add('force-hidde');
                return;
             }
             if (curTipoSlugs.length == 0) {
                carac.parentNode.classList.remove('force-hidde');
                return;
             }
             if (curTipoSlugs.indexOf(this.getTipoCarac(carac.getAttribute('data-id'))) >= 0) {
                carac.parentNode.classList.remove('force-hidde');
             } else {
                carac.parentNode.classList.add('force-hidde');
             }
        });
        let filterCaracs = Array.from(caracs)?.filter((v, i) => (!v.parentNode.classList.contains('force-hidde')));
        filterCaracs?.forEach((carac, i) => {
            if (i < 5) {
                carac.parentNode.classList.remove('hidde');
            } else if (!carac.classList.contains('hidde')) {
                carac.parentNode.classList.add('hidde');
            }
        });
    }

    searchChar(e) {
        let input = e.target;
        let searchTerm = input.value;
        let list = this.element.querySelector('.list-caracs');
        let caracs = this.element.querySelectorAll('input[name=caracteristicas]');
        // caracs?.forEach(carac => {
        //     carac.parentNode.classList.remove('force-hidde');
        // });
        if (searchTerm != "" && searchTerm != " ") {
            input.parentNode.classList.add('with-content');
            list?.classList.add('open');
            // caracs?.forEach(carac => {
            //     let nome = carac.getAttribute('data-nome');
            //     if (nome.toLowerCase().indexOf(searchTerm.toLowerCase()) === -1) {
            //         carac.parentNode.classList.add('force-hidde');
            //     }
            // });
        } else {
            list?.classList.remove('open');
            input.parentNode.classList.remove('with-content');
        }
        this.updateVibilityCaracs(searchTerm);
    }

    showAllChar() {
        let list = this.element.querySelector('.list-caracs');
        if (!list.classList.contains('open')) {
            list.classList.add('open'); 
        } else {
            list.classList.remove('open'); 
        }
    }

    goUrlFiltros() {
        var filtrosArray = [];
    
        if (imoveis_filtros.tipos) filtrosArray.push('tipo='+imoveis_filtros.tipos);
        if (imoveis_filtros.transacao) filtrosArray.push('transacao='+imoveis_filtros.transacao);
        if (imoveis_filtros.termo) filtrosArray.push('termo='+imoveis_filtros.termo);
        if (imoveis_filtros.codigo) filtrosArray.push('codigo='+imoveis_filtros.codigo);
        if (imoveis_filtros.valor) filtrosArray.push('valor='+imoveis_filtros.valor);
        if (imoveis_filtros.area) filtrosArray.push('area='+imoveis_filtros.area);
        if (imoveis_filtros.status) filtrosArray.push('status='+imoveis_filtros.status);
        if (imoveis_filtros.cidades) filtrosArray.push('cidades='+imoveis_filtros.cidades);
        if (imoveis_filtros.bairros) filtrosArray.push('bairros='+imoveis_filtros.bairros);
        if (imoveis_filtros.cidade) filtrosArray.push('cidade='+imoveis_filtros.cidade);
        if (imoveis_filtros.bairro) filtrosArray.push('bairro='+imoveis_filtros.bairro);
        if (imoveis_filtros.dormitorios) filtrosArray.push('dormitorios='+imoveis_filtros.dormitorios);
        if (imoveis_filtros.suites) filtrosArray.push('suites='+imoveis_filtros.suites);
        if (imoveis_filtros.banheiros) filtrosArray.push('banheiros='+imoveis_filtros.banheiros);
        if (imoveis_filtros.vagas) filtrosArray.push('vagas='+imoveis_filtros.vagas);
        if (imoveis_filtros.caracteristicas) filtrosArray.push('caracteristicas='+imoveis_filtros.caracteristicas);
        if (imoveis_filtros.valor_minimo) filtrosArray.push('valor_minimo='+imoveis_filtros.valor_minimo);
        if (imoveis_filtros.valor_maximo) filtrosArray.push('valor_maximo='+imoveis_filtros.valor_maximo);
        if (imoveis_filtros.area_minimo) filtrosArray.push('area_minimo='+imoveis_filtros.area_minimo);
        if (imoveis_filtros.area_maximo) filtrosArray.push('area_maximo='+imoveis_filtros.area_maximo);
        if (imoveis_filtros.area_const_minimo) filtrosArray.push('area_const_minimo='+imoveis_filtros.area_const_minimo);
        if (imoveis_filtros.area_const_maximo) filtrosArray.push('area_const_maximo='+imoveis_filtros.area_const_maximo);
    
        var url_imoveis = (filtrosArray && filtrosArray.length > 0) ? filtrosArray.join('&') : '';
    
        window.location.href = '/imoveis/' + url_imoveis;
    }
}