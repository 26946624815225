import { Controller } from 'stimulus';

export default class extends Controller {
    connect() {
        this.triggers = this.element.querySelectorAll('.faq-trigger');
        this.onClickHandler = this.onClick.bind(this);
        this.triggers?.forEach(trigger => {
            trigger.addEventListener('click', (e) => this.onClickHandler(trigger));
        });
    }
    onClick(trigger) {
        let content = trigger.parentElement.querySelector('.faq-content');
        let arrowIcon = trigger.querySelector('i');
        let showClasses = content.getAttribute('data-show-classes').split(' ');
        let hiddenClasses = content.getAttribute('data-hidden-classes').split(' ');
        if (content.classList.contains('show')) {
            content.classList.remove('show');
            content.classList.remove(...showClasses);
            content.classList.add(...hiddenClasses);
            arrowIcon.classList.add('-rotate-90');
        } else {
            content.classList.add('show');
            content.classList.remove(...hiddenClasses);
            content.classList.add(...showClasses);
            arrowIcon.classList.remove('-rotate-90');
        }
    }
}
