import { Controller } from 'stimulus';
import PhotoSwipeLightbox from "photoswipe/src/js/lightbox/lightbox";
import PhotoSwipe from 'photoswipe/src/js/photoswipe';
// import 'photoswipe/src/photoswipe.css';
import Swiper from 'swiper';

export default class extends Controller {
    connect() {
        const options = {
            gallery: this.element,
            children: 'a',
            showHideAnimationType: 'zoom',
            loop: false,
            initialZoomLevel: 0.85,
            pswpModule: () => PhotoSwipe
        };
        const lightbox = new PhotoSwipeLightbox(options);
        lightbox.init();

        this.areaThumbs = this.element.querySelector(".area-thumbs");
        this.areaPhotos = this.element.querySelector(".area-photos");

        var galleryThumbs = new Swiper(this.areaThumbs, {
            spaceBetween: 10,
            slidesPerView: 4,
            freeMode: true,
            direction: 'vertical',
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                480: {
                    slidesPerView: 2,
                },
                780: {
                    slidesPerView: 3,
                },
                1000: {
                    slidesPerView: 4
                }
            }
        });
        var galleryTop = new Swiper(this.areaPhotos, {
            spaceBetween: 15,
            navigation: {
                nextEl: this.element.querySelector('.swiper-button-next'),
                prevEl: this.element.querySelector('.swiper-button-prev'),
            },
            thumbs: {
                swiper: galleryThumbs
            },
            pagination: {
                el: this.element.querySelector('.swiper-pagination'),
                type: 'bullets',
                clickable: true
            },
        });
    }
}
