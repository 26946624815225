import { Controller } from 'stimulus';
import { toast, DragDropOrder } from '../utils'; 
import axios from 'axios';
export default class extends Controller {

    actionsButtonsTemplate =
            '<button type="button" class="btn btn-white" onclick="location.reload(); return false;">Resetar</button>'+
            '<button type="button" class="btn btn-primary btn-send-save-order" type="button" data-form-name="order">Salvar ordenação</button>';

    connect() {
        this.inputOrdenar = this.element.querySelector('input.ordenar');
        this.for = this.inputOrdenar.getAttribute("data-for");
        this.blockLayout = document.querySelectorAll('.BlockLayout[data-draggable-name='+this.for+']');
        this.sortable = this.element.getAttribute('data-sortable');
        this.dragDropOrder = null;
        this.onChangeInputOrderHandler = this.onChangeInputOrder.bind(this);
        this.onDragStopHandler = this.onDragStop.bind(this);
        this.inputOrdenar.addEventListener('change', this.onChangeInputOrderHandler);
    }
    onChangeInputOrder(e) {
        let checked = this.inputOrdenar.checked;
        if (checked) {
            this.dragDropOrder = DragDropOrder(this.blockLayout, this.onDragStopHandler, this.sortable);
            // show actions buttons
            this.showActionsButtons();
        } else {
            if (this.dragDropOrder) { this.dragDropOrder.destroy(); }
            // hide actions buttons
            this.hideActionsButtons();
        }
    }
    onDragStop(e) {}
    onSave(e) {
        let childNodes = this.blockLayout[0].childNodes,
            numChildNodes = childNodes.length,
            nodeIndex = 0, 
            newOrder = [],
            ident = this.blockLayout[0].getAttribute('data-entity');

        if (childNodes) {
            for (var i = 0; i < numChildNodes; i++) {
                if(childNodes[i].nodeType !== 1 || (childNodes[i].tagName && childNodes[i].tagName.toUpperCase() !== 'DIV' && childNodes[i].tagName.toUpperCase() !== 'TR')) { 
                    continue; 
                }
                if(!childNodes[i].classList.contains('Block--isDraggable') || childNodes[i].classList.contains('draggable-mirror')) { 
                    continue; 
                }
                nodeIndex++;
                childNodes[i].setAttribute('data-order', nodeIndex);
                newOrder.push({ id: childNodes[i].getAttribute('data-id'), order: nodeIndex });
            }
        }
        console.log(newOrder);
        //
        e.target.classList.add('loading');
        e.target.disabled = true;
        try {
            const params = new URLSearchParams();
            params.append('ident', ident);
            newOrder.forEach(value => {
                params.append('orderable['+value.id+']', value.order);
            });
            axios.post(`${PATHS.admin_save_order}`, params).then(data => {
                e.target.classList.remove('loading');
                e.target.disabled = false;
                if (data.status == false) {
                    toast({
                        type: 'error',
                        title: 'Ocorreu um erro ao alterar a ordem!'
                    });
                } else {
                    window.location.reload();
                }
            });
        } catch (error) {
            toast({
                type: 'error',
                title: 'Ocorreu um erro'
            });
            e.target.classList.remove('loading');
            e.target.disabled = false;
            console.log(error);
        }
    }
    showActionsButtons() {
        let actionsButtons = document.createElement('div');
        actionsButtons.classList += 'order-action-buttons';
        actionsButtons.innerHTML = this.actionsButtonsTemplate;
        this.actionsButtons = document.body.insertAdjacentElement('beforeend', actionsButtons);
        let aB = this.actionsButtons;
        setTimeout(() => {
            aB.classList.add('show');
        }, 50);
        this.onSaveHandler = this.onSave.bind(this);
        this.actionsButtons.querySelector('.btn-send-save-order').addEventListener('click', this.onSaveHandler);
    }
    hideActionsButtons() {
        this.actionsButtons.remove();
    }
    close() {
        this.element.remove();
    }
}