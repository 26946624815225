import { Controller } from 'stimulus';
import { EventEmitter } from '../utils/events';

export default class extends Controller {
    connect() {
        this.sticked = false;
        this.progressBtn = this.element.querySelector('.progress-button');
        this.progressCircle = this.element.querySelector('svg .page-progress');
        let onProgressClickHandler = this.onProgressClick.bind(this);
        let checkTopHandler = this.checkTop.bind(this);
        let calculateProgressHandler = this.calculateProgress.bind(this);
        this.progressBtn.addEventListener('click', e => onProgressClickHandler());
        checkTopHandler();
        calculateProgressHandler();
        window.addEventListener('scroll', e => {
            checkTopHandler();
            calculateProgressHandler();
        });

        EventEmitter.subscribe('recalculate_scroll', () => {
            calculateProgressHandler();
        });
    }

    onProgressClick() {
        const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
        if (supportsNativeSmoothScroll) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        } else {
            window.scrollTo(0,0);
        }
    }

    checkTop() {
        let rect = this.element.getBoundingClientRect();
        if (rect.top == 0 && !this.sticked) {
            this.sticked = true;
            this.element.classList.add('border-b', 'border-zinc-200', 'dark:border-zinc-700');
            this.progressBtn.classList.add('flex');
            this.progressBtn.classList.remove('hidden');
            return;
        }
        if (rect.top != 0 && this.sticked) {
            this.sticked = false;
            this.element.classList.remove('border-b', 'border-zinc-200', 'dark:border-zinc-700');
            this.progressBtn.classList.remove('flex');
            this.progressBtn.classList.add('hidden');
        }
    }

    calculateProgress() {
        var scrollPosY = window.scrollY;
        var totalArea = document.body.getBoundingClientRect().height;
        var relativeScroll = scrollPosY;
        var perRelativeScroll = ((relativeScroll <= 0 ? 0 : relativeScroll) / totalArea);
        this.progressCircle.style.strokeDashoffset = 157.08 - (157.08 * (perRelativeScroll > 1 ? 1 : perRelativeScroll));
    }
}
